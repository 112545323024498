import {
  connectorsForWallets,
  getDefaultWallets,
} from "@rainbow-me/rainbowkit";
import { trustWallet } from "@rainbow-me/rainbowkit/wallets";
import {
  Chain,
  chain,
  ChainProviderFn,
  configureChains,
  createClient,
} from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";

// export const mainnetTenderlyFork: Chain = {
//   ...chain.mainnet,
//   id: 1,
//   name: "Mainnet Tenderly Fork",
//   rpcUrls: {
//     default:
//       "https://rpc.tenderly.co/fork/9b9d6e22-fae5-407a-b98d-07249d3aa20e",
//   },
// };
// const mainnetTenderlyForkProvider: ChainProviderFn = jsonRpcProvider({
//   priority: 0,
//   rpc: (chain) => ({
//     http: mainnetTenderlyFork.rpcUrls.default,
//   }),
// });

const supportedChainshains: Chain[] = [chain.mainnet];
const supportedProviders: ChainProviderFn[] = [
  alchemyProvider({ apiKey: "AEtZpckav3marVzv5FPIbDyddDAkewJ6" }),
];
// if (process.env.NODE_ENV === "development") {
//   supportedChainshains.push(
//     mainnetTenderlyFork,
//     chain.localhost,
//     chain.hardhat
//   );
//   supportedProviders.push(mainnetTenderlyForkProvider);
// }

const chainConfig = configureChains(supportedChainshains, supportedProviders);

export const { chains } = chainConfig;
const { provider, webSocketProvider } = chainConfig;

const { wallets } = getDefaultWallets({
  appName: "Ape Now Pay Later",
  chains,
});
wallets.push({ groupName: "Popular", wallets: [trustWallet({ chains })] });

const connectors = connectorsForWallets([...wallets]);

export const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
  connectors,
});
