import { SUBMITTED_ROUTE } from "constants/routes";

import { signOffchainOffer } from "@clarity-credit/anpl-sdk";
import { RootState } from "app/store";
import { ReactComponent as Close } from "assets/close.svg";
import Checkbox from "components/checkbox";
import ConnectedButton from "components/connectedButton";
import EthPrice from "components/tokenPrice";
import Input from "components/input";
import Spinner from "components/spinner";
import { BigNumber, ethers } from "ethers";
import { useConvertETHToUSD } from "hooks/useConvertETHToUSD";
import { useGetDomainData } from "hooks/useGetDomainData";
import { UserType, useVerifyTransaction } from "hooks/useVerifyTransaction";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePostOfferMutation } from "slices/anplApiSlice";
import { clearCart, selectAllCartItems } from "slices/cartSlice";
import { generateOfferString, prepareOfferTerms } from "utils/offerUtils";
import { createPaymentDurationString } from "utils/paymentUtils";
import { getAbsoluteRoute } from "utils/routerUtils";
import { useAccount, useSigner } from "wagmi";

import CartItem from "./cartItem";

import "./styles.scss";

const calculateDurationString = (paymentDurations: Set<number>) => {
  const arr = [...paymentDurations];
  if (arr.length === 1) return arr[0];
  else return `${Math.min(...arr)} - ${Math.max(...arr)}`;
};

export const Checkout: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { data: signer } = useSigner();
  const { address } = useAccount();
  const cartItems = useSelector((state: RootState) =>
    selectAllCartItems(state)
  );
  const dispatch = useDispatch();
  const offerString = generateOfferString(cartItems.length);
  const [postOffer] = usePostOfferMutation();

  const [isAcceptTnC, setIsAcceptTnC] = useState(false);

  const defaultAggregateAssets = useMemo(
    () => ({
      totalDownpayment: BigNumber.from(0),
      totalMonthlyPayments: BigNumber.from(0),
      paymentDurations: new Set<number>([]),
      grandTotal: BigNumber.from(0),
    }),
    []
  );

  const aggregateAssets = useMemo(
    () =>
      cartItems.reduce(
        (prev, curr) => ({
          totalDownpayment: prev.totalDownpayment.add(
            BigNumber.from(curr.offerTerms?.downPayment ?? 0)
          ),
          totalMonthlyPayments: prev.totalMonthlyPayments.add(
            BigNumber.from(curr.payments?.[0]?.paymentAmount ?? 0)
          ),
          paymentDurations: prev.paymentDurations.add(
            curr.payments?.length ?? 0
          ),
          grandTotal: prev.grandTotal.add(
            BigNumber.from(curr.offerTerms?.totalPayLater ?? 0).add(
              BigNumber.from(curr.offerTerms?.downPayment ?? 0)
            )
          ),
        }),
        defaultAggregateAssets
      ),
    [cartItems, defaultAggregateAssets]
  );

  const { ethAmountInUSD: totalDownPaymentInUSD } = useConvertETHToUSD(
    aggregateAssets.totalDownpayment.toString()
  );

  const { hasAllowance, verify } = useVerifyTransaction(
    // TODO: is converted to WETH address in the hook
    ethers.constants.AddressZero,
    BigNumber.from(aggregateAssets.totalDownpayment).toString(),
    UserType.Borrower
  );

  const domainData = useGetDomainData();
  const handleMakeOffers = () => {
    if (!signer) return;

    setIsLoading(true);
    Promise.all(
      cartItems.map(async (item) => {
        const submitBidArgs = prepareOfferTerms(item.offerTerms!, address!);
        let borrowerSignature = "";
        borrowerSignature = await signOffchainOffer({
          basicOrderParams: item.basicOrderParams!,
          domainData,
          submitBidArgs,
          signer,
        });
        return {
          basicOrderParams: item.basicOrderParams!,
          domainData,
          submitBidArgs,
          borrowerSignature,
        };
      })
    )
      .then(
        (offers) => {
          postOffer({ offers })
            .then((result) => {
              if ("error" in result) throw result.error;
              if ("error" in result.data) throw result.data.error;
              navigate(getAbsoluteRoute(SUBMITTED_ROUTE), {
                state: { offerString },
              });
              dispatch(clearCart());

              void verify().catch((err) => {
                setError("Unable to submit token allowance transaction");
                console.error("Error submitting token allowance\n", err);
              });
            })
            .catch((err) => {
              setError("Unable to submit offers");
              console.error("Error posting offers\n", err);
            });
        },
        (err) => {
          setError("Failed to sign offers");
          console.error("Error signing offers\n", err);
        }
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="checkout-page dynamic-height">
      {isLoading && (
        <div className="overlay-spinner">
          <Spinner />
        </div>
      )}
      <>
        <div className="checkout-page-title">
          <h2>Complete checkout</h2>
          <Close onClick={() => navigate(-1)} className="clickable-icon" />
        </div>
        <h3 className="offers-title capitalize">{offerString}</h3>
        <div className="items">
          {cartItems.map((item, index) => (
            <CartItem asset={item} key={index} />
          ))}
        </div>
        {cartItems.length > 0 && (
          <div className="confirmation-container">
            <Input
              label="Email notifications (optional)"
              placeholder="Email to receive offer updates"
              className="email-input"
            />
            <div className="total-due">
              <h3>Total due when accepted</h3>
              <div className="total-due-amounts">
                <EthPrice price={aggregateAssets.totalDownpayment.toString()} />
                <span className="secondary-text">{totalDownPaymentInUSD}</span>
                <span className="secondary-text">
                  {createPaymentDurationString(
                    aggregateAssets.totalMonthlyPayments.toString(),
                    calculateDurationString(
                      aggregateAssets.paymentDurations
                    ).toString(),
                    true
                  )}
                </span>
              </div>
            </div>
            <div className="grand-total">
              <h3>Grand total</h3>
              <EthPrice price={aggregateAssets.grandTotal.toString()} />
            </div>
            <Checkbox
              checked={isAcceptTnC}
              setChecked={setIsAcceptTnC}
              label={
                <>
                  I agree to the <a href=""> terms and conditions</a>
                </>
              }
            />
            <ConnectedButton
              label={`Make ${offerString}`}
              disabled={!isAcceptTnC}
              onClick={handleMakeOffers}
            />
            {error && <div className="error-message">{error}</div>}
          </div>
        )}
      </>
    </div>
  );
};
