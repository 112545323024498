import { ReactComponent as BlueCheck } from "assets/blue_check.svg";
import { ReactComponent as Search } from "assets/search.svg";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import {
  components,
  ControlProps,
  MenuPlacement,
  MenuProps,
  OptionProps,
  ValueContainerProps,
} from "react-select";
import AsyncSelect from "react-select/async";
import { useLazyGetSearchResultsQuery } from "slices/anplApiSlice";
import { generateCollectionRouteFromSlug } from "utils/routerUtils";

import "./styles.scss";

interface SearchCollectionProps {
  placement?: MenuPlacement;
}

interface CollectionOption {
  value: string;
  label: string;
  alchemyImageUri: string;
}

const Control = ({
  children,
  menuIsOpen,
  isFocused,
  ...props
}: ControlProps<CollectionOption>) => {
  return (
    <components.Control
      {...props}
      className={cx(
        "search-collection-input",
        menuIsOpen && "menu-open",
        isFocused && "focused"
      )}
      menuIsOpen={menuIsOpen}
      isFocused={isFocused}
    >
      <Search className="search-icon" height={25} width={25} />
      {children}
    </components.Control>
  );
};

const Menu = (props: MenuProps<CollectionOption>) => (
  <components.Menu {...props} className={cx("options", props.placement)} />
);

const Option = (props: OptionProps<CollectionOption>) => {
  const navigate = useNavigate();
  return (
    <div
      className="option"
      onClick={() =>
        navigate(generateCollectionRouteFromSlug(props.data.value))
      }
    >
      <div className="option-content row">
        <img src={props.data.alchemyImageUri} />
        <components.Option {...props} className="option-item" />
        <BlueCheck />
      </div>
    </div>
  );
};

const ValueContainer = (props: ValueContainerProps<CollectionOption>) => (
  <components.ValueContainer {...props} className="value" />
);

export const SearchCollection: React.FC<SearchCollectionProps> = ({
  placement = "bottom",
}) => {
  const [searchCollection] = useLazyGetSearchResultsQuery();

  const handleChange = async (value: string) => {
    let values: CollectionOption[] = [];
    await searchCollection(value).then((response) => {
      const collections = response.data?.result;
      if (collections?.length)
        values = collections.map((collection) => ({
          value: collection.openseaSlug,
          label: collection.name,
          alchemyImageUri: collection.alchemyImageUri,
        }));
    });

    return values;
  };

  const components = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    Control,
    Menu,
    Option,
    ValueContainer,
  };

  return (
    <AsyncSelect
      components={components}
      loadOptions={handleChange}
      openMenuOnClick={false}
      className={cx("search-collection", placement)}
      placeholder="Search ApeNow"
      menuPlacement={placement}
    />
  );
};
