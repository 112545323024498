import { ReactComponent as Loans } from "assets/my_loans.svg";
import { DesktopComponent } from "components/responsiveComponents";
import Spinner from "components/spinner";
import { FC } from "react";
import { ComponentWithChildren } from "types/Component";

import "./styles.scss";

interface DesktopAssetsViewProps extends ComponentWithChildren {
  title: string;
  subtitle: string;
  isLoading?: boolean;
}

export const DesktopAssetsView: FC<DesktopAssetsViewProps> = ({
  title,
  subtitle,
  children,
  isLoading,
}) => {
  return (
    <DesktopComponent>
      <div className="desktop-asset-view">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="header">
              <Loans />
              <div>
                <h1>{title}</h1>
                <p>{subtitle}</p>
              </div>
            </div>
            <div className="asset-grid">{children}</div>
          </>
        )}
      </div>
    </DesktopComponent>
  );
};
