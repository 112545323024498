import AssetBasicInfo from "components/assetBasicInfo";
import Button, { VARIANTS } from "components/button";
import TokenPrice from "components/tokenPrice";
import PaymentBasicInfo from "components/paymentBasicInfo";
import { useNavigate } from "react-router-dom";
import { PendingRequestItem } from "slices/anplApiSlice";
import { SIZES } from "types/sizes";
import { convertDurationToMonths } from "utils/dateUtils";
import { createPaymentDurationString } from "utils/paymentUtils";
import { generateAssetRouteFromIAsset } from "utils/routerUtils";
import { Token } from "components/tokenPrice/TokenPrice";

interface PendingRequestsItemProps {
  record: PendingRequestItem;
}

export const PendingRequestsItem: React.FC<PendingRequestsItemProps> = ({
  record,
}) => {
  const asset = record.asset;
  const { submitBidArgs } = record;
  const navigate = useNavigate();
  return (
    <div
      className="item"
      onClick={() => navigate(generateAssetRouteFromIAsset(asset))}
    >
      <img src={asset?.alchemyImageUri} alt={`${asset?.displayName} img`} />
      <div className="item-info">
        <div className="row">
          <AssetBasicInfo asset={asset} />
          <div>
            <TokenPrice
              price={submitBidArgs.downPayment}
              token={asset.currencyType as Token}
            />
            <div className="secondary-text italic">
              {createPaymentDurationString(
                submitBidArgs.downPayment,
                convertDurationToMonths(submitBidArgs.duration).toFixed(0)
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <PaymentBasicInfo offerTerms={submitBidArgs} />
          {/*           <Button
            variant={VARIANTS.SECONDARY}
            label="Cancel"
            size={SIZES.COMPRESSED}
          /> */}
        </div>
      </div>
    </div>
  );
};
