import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ReactComponent as WalletConnected } from "assets/wallet_connected.svg";
import { ReactComponent as WalletNotConnected } from "assets/wallet_not_connected.svg";
import Button, { VARIANTS } from "components/button";
import { Popover } from "components/popover/Popover";
import { DesktopComponent } from "components/responsiveComponents";
import { formatAddressString } from "utils/addressUtils";
import { useAccount } from "wagmi";

import "./styles.scss";
import { WalletPopover } from "./WalletPopover";

export const WalletButton = () => {
  const { address } = useAccount();
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <>
            {(() => {
              if (!connected) {
                return (
                  <WalletNotConnected
                    onClick={openConnectModal}
                    type="button"
                    className="clickable-icon"
                    width="20px"
                    height="20px"
                    data-test="wallet-not-connected"
                  />
                );
              }
              if (chain.unsupported) {
                return (
                  <Button
                    label={
                      <div className="wrong-network-label">Wrong Network</div>
                    }
                    onClick={openChainModal}
                    variant={VARIANTS.TEXT}
                  />
                );
              }
              return (
                <div className="clickable-icon-container">
                  <Popover
                    render={({ close }) => <WalletPopover close={close} />}
                  >
                    <div className="wallet-info">
                      <WalletConnected
                        className="clickable-icon"
                        height="20px"
                        width="20px"
                        data-test="wallet-connected"
                      />
                      <DesktopComponent>
                        <div className="wallet-address">
                          {formatAddressString(address!)}
                        </div>
                      </DesktopComponent>
                    </div>
                  </Popover>
                </div>
              );
            })()}
          </>
        );
      }}
    </ConnectButton.Custom>
  );
};
