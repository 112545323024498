const ERC20ABI = [
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_spender",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_from",
        type: "address",
      },
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
      {
        name: "_spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
];

export default {
  // mainnet
  1: {
    contracts: {
      BNPLMarket: {
        address: "0x260c32eb38d1403bd51b83b5b7047812c70b7845",
        abi: [
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "address",
                name: "previousAdmin",
                type: "address",
              },
              {
                indexed: false,
                internalType: "address",
                name: "newAdmin",
                type: "address",
              },
            ],
            name: "AdminChanged",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "implementation",
                type: "address",
              },
            ],
            name: "Upgraded",
            type: "event",
          },
          { stateMutability: "payable", type: "fallback" },
          {
            inputs: [],
            name: "admin",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "newAdmin", type: "address" },
            ],
            name: "changeAdmin",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "implementation",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "newImplementation",
                type: "address",
              },
            ],
            name: "upgradeTo",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "newImplementation",
                type: "address",
              },
              { internalType: "bytes", name: "data", type: "bytes" },
            ],
            name: "upgradeToAndCall",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          { stateMutability: "payable", type: "receive" },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint256",
                name: "discreteOrderId",
                type: "uint256",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "bidId",
                type: "uint256",
              },
            ],
            name: "AcceptedDiscreteOrder",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint256",
                name: "bidId",
                type: "uint256",
              },
              {
                indexed: true,
                internalType: "address",
                name: "borrower",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "lender",
                type: "address",
              },
            ],
            name: "AssetPurchaseWithLoan",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint256",
                name: "discreteOrderId",
                type: "uint256",
              },
            ],
            name: "CancelledDiscreteOrder",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "uint256",
                name: "bidId",
                type: "uint256",
              },
              {
                indexed: false,
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                indexed: false,
                internalType: "address",
                name: "tokenContract",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
              },
            ],
            name: "ClaimedAsset",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint256",
                name: "discreteOrderId",
                type: "uint256",
              },
              {
                indexed: true,
                internalType: "address",
                name: "borrower",
                type: "address",
              },
            ],
            name: "CreatedDiscreteOrder",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint8",
                name: "version",
                type: "uint8",
              },
            ],
            name: "Initialized",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint256",
                name: "marketId",
                type: "uint256",
              },
              {
                indexed: false,
                internalType: "address",
                name: "_feeRecipient",
                type: "address",
              },
              {
                indexed: false,
                internalType: "bool",
                name: "allowed",
                type: "bool",
              },
            ],
            name: "SetAllowedFeeRecipient",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: "uint256",
                name: "marketId",
                type: "uint256",
              },
              {
                indexed: false,
                internalType: "uint16",
                name: "pct",
                type: "uint16",
              },
            ],
            name: "SetReferralReward",
            type: "event",
          },
          {
            inputs: [],
            name: "CONTRACT_NAME",
            outputs: [{ internalType: "string", name: "", type: "string" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "CONTRACT_VERSION",
            outputs: [{ internalType: "string", name: "", type: "string" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "CRA_SIGNER",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "DOMAIN_SEPARATOR",
            outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "ETH_ADDRESS",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "__assetReceiptRegister",
            outputs: [
              {
                internalType: "address",
                name: "assetContractAddress",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "assetTokenId",
                type: "uint256",
              },
              { internalType: "uint256", name: "quantity", type: "uint256" },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "_marketRegistry",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "_tellerV2",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "_discreteOrderId",
                type: "uint256",
              },
              {
                components: [
                  {
                    internalType: "address",
                    name: "considerationToken",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "considerationIdentifier",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "considerationAmount",
                    type: "uint256",
                  },
                  {
                    internalType: "address payable",
                    name: "offerer",
                    type: "address",
                  },
                  { internalType: "address", name: "zone", type: "address" },
                  {
                    internalType: "address",
                    name: "offerToken",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "offerIdentifier",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "offerAmount",
                    type: "uint256",
                  },
                  {
                    internalType: "enum BasicOrderType",
                    name: "basicOrderType",
                    type: "uint8",
                  },
                  {
                    internalType: "uint256",
                    name: "startTime",
                    type: "uint256",
                  },
                  { internalType: "uint256", name: "endTime", type: "uint256" },
                  {
                    internalType: "bytes32",
                    name: "zoneHash",
                    type: "bytes32",
                  },
                  { internalType: "uint256", name: "salt", type: "uint256" },
                  {
                    internalType: "bytes32",
                    name: "offererConduitKey",
                    type: "bytes32",
                  },
                  {
                    internalType: "bytes32",
                    name: "fulfillerConduitKey",
                    type: "bytes32",
                  },
                  {
                    internalType: "uint256",
                    name: "totalOriginalAdditionalRecipients",
                    type: "uint256",
                  },
                  {
                    components: [
                      {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                      },
                      {
                        internalType: "address payable",
                        name: "recipient",
                        type: "address",
                      },
                    ],
                    internalType: "struct AdditionalRecipient[]",
                    name: "additionalRecipients",
                    type: "tuple[]",
                  },
                  { internalType: "bytes", name: "signature", type: "bytes" },
                ],
                internalType: "struct BasicOrderParameters",
                name: "_basicOrderParameters",
                type: "tuple",
              },
            ],
            name: "acceptDiscreteOrder",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "uint256", name: "", type: "uint256" },
              { internalType: "address", name: "", type: "address" },
            ],
            name: "allowedFeeRecipients",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "_discreteOrderId",
                type: "uint256",
              },
            ],
            name: "cancelDiscreteOrder",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "uint256", name: "bidId", type: "uint256" },
            ],
            name: "claimNFTFromDefaultedLoan",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "uint256", name: "bidId", type: "uint256" },
            ],
            name: "claimNFTFromRepaidLoan",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "cryptopunksEscrowBuyer",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "cryptopunksMarketAddress",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "discreteOrderCount",
            outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            name: "discreteOrders",
            outputs: [
              {
                components: [
                  {
                    internalType: "address",
                    name: "borrower",
                    type: "address",
                  },
                  { internalType: "address", name: "lender", type: "address" },
                  {
                    internalType: "uint256",
                    name: "totalPurchasePrice",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "principal",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "downPayment",
                    type: "uint256",
                  },
                  { internalType: "uint32", name: "duration", type: "uint32" },
                  {
                    internalType: "uint32",
                    name: "signatureExpiration",
                    type: "uint32",
                  },
                  {
                    internalType: "uint16",
                    name: "interestRate",
                    type: "uint16",
                  },
                  {
                    internalType: "string",
                    name: "metadataURI",
                    type: "string",
                  },
                  {
                    internalType: "address",
                    name: "referralAddress",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "marketId",
                    type: "uint256",
                  },
                ],
                internalType: "struct BNPLMarket.SubmitBidArgs",
                name: "submitBidArgs",
                type: "tuple",
              },
              {
                internalType: "address",
                name: "assetContractAddress",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "assetTokenId",
                type: "uint256",
              },
              { internalType: "uint256", name: "quantity", type: "uint256" },
              { internalType: "uint256", name: "bidId", type: "uint256" },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
            name: "escrowedTokensForLoan",
            outputs: [
              {
                internalType: "enum IBNPLMarket.TokenType",
                name: "tokenType",
                type: "uint8",
              },
              {
                internalType: "address",
                name: "tokenAddress",
                type: "address",
              },
              { internalType: "uint256", name: "tokenId", type: "uint256" },
              { internalType: "uint256", name: "amount", type: "uint256" },
              { internalType: "bool", name: "tokenClaimed", type: "bool" },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                components: [
                  {
                    internalType: "address",
                    name: "borrower",
                    type: "address",
                  },
                  { internalType: "address", name: "lender", type: "address" },
                  {
                    internalType: "uint256",
                    name: "totalPurchasePrice",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "principal",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "downPayment",
                    type: "uint256",
                  },
                  { internalType: "uint32", name: "duration", type: "uint32" },
                  {
                    internalType: "uint32",
                    name: "signatureExpiration",
                    type: "uint32",
                  },
                  {
                    internalType: "uint16",
                    name: "interestRate",
                    type: "uint16",
                  },
                  {
                    internalType: "string",
                    name: "metadataURI",
                    type: "string",
                  },
                  {
                    internalType: "address",
                    name: "referralAddress",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "marketId",
                    type: "uint256",
                  },
                ],
                internalType: "struct BNPLMarket.SubmitBidArgs",
                name: "_submitBidArgs",
                type: "tuple",
              },
              {
                components: [
                  {
                    internalType: "address",
                    name: "considerationToken",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "considerationIdentifier",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "considerationAmount",
                    type: "uint256",
                  },
                  {
                    internalType: "address payable",
                    name: "offerer",
                    type: "address",
                  },
                  { internalType: "address", name: "zone", type: "address" },
                  {
                    internalType: "address",
                    name: "offerToken",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "offerIdentifier",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "offerAmount",
                    type: "uint256",
                  },
                  {
                    internalType: "enum BasicOrderType",
                    name: "basicOrderType",
                    type: "uint8",
                  },
                  {
                    internalType: "uint256",
                    name: "startTime",
                    type: "uint256",
                  },
                  { internalType: "uint256", name: "endTime", type: "uint256" },
                  {
                    internalType: "bytes32",
                    name: "zoneHash",
                    type: "bytes32",
                  },
                  { internalType: "uint256", name: "salt", type: "uint256" },
                  {
                    internalType: "bytes32",
                    name: "offererConduitKey",
                    type: "bytes32",
                  },
                  {
                    internalType: "bytes32",
                    name: "fulfillerConduitKey",
                    type: "bytes32",
                  },
                  {
                    internalType: "uint256",
                    name: "totalOriginalAdditionalRecipients",
                    type: "uint256",
                  },
                  {
                    components: [
                      {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                      },
                      {
                        internalType: "address payable",
                        name: "recipient",
                        type: "address",
                      },
                    ],
                    internalType: "struct AdditionalRecipient[]",
                    name: "additionalRecipients",
                    type: "tuple[]",
                  },
                  { internalType: "bytes", name: "signature", type: "bytes" },
                ],
                internalType: "struct BasicOrderParameters",
                name: "_basicOrderParameters",
                type: "tuple",
              },
              { internalType: "bytes", name: "_signature", type: "bytes" },
            ],
            name: "execute",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [
              {
                components: [
                  {
                    internalType: "address",
                    name: "borrower",
                    type: "address",
                  },
                  { internalType: "address", name: "lender", type: "address" },
                  {
                    internalType: "uint256",
                    name: "totalPurchasePrice",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "principal",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "downPayment",
                    type: "uint256",
                  },
                  { internalType: "uint32", name: "duration", type: "uint32" },
                  {
                    internalType: "uint32",
                    name: "signatureExpiration",
                    type: "uint32",
                  },
                  {
                    internalType: "uint16",
                    name: "interestRate",
                    type: "uint16",
                  },
                  {
                    internalType: "string",
                    name: "metadataURI",
                    type: "string",
                  },
                  {
                    internalType: "address",
                    name: "referralAddress",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "marketId",
                    type: "uint256",
                  },
                ],
                internalType: "struct BNPLMarket.SubmitBidArgs",
                name: "_submitBidArgs",
                type: "tuple",
              },
              {
                components: [
                  {
                    internalType: "address",
                    name: "considerationToken",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "considerationIdentifier",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "considerationAmount",
                    type: "uint256",
                  },
                  {
                    internalType: "address payable",
                    name: "offerer",
                    type: "address",
                  },
                  { internalType: "address", name: "zone", type: "address" },
                  {
                    internalType: "address",
                    name: "offerToken",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "offerIdentifier",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "offerAmount",
                    type: "uint256",
                  },
                  {
                    internalType: "enum BasicOrderType",
                    name: "basicOrderType",
                    type: "uint8",
                  },
                  {
                    internalType: "uint256",
                    name: "startTime",
                    type: "uint256",
                  },
                  { internalType: "uint256", name: "endTime", type: "uint256" },
                  {
                    internalType: "bytes32",
                    name: "zoneHash",
                    type: "bytes32",
                  },
                  { internalType: "uint256", name: "salt", type: "uint256" },
                  {
                    internalType: "bytes32",
                    name: "offererConduitKey",
                    type: "bytes32",
                  },
                  {
                    internalType: "bytes32",
                    name: "fulfillerConduitKey",
                    type: "bytes32",
                  },
                  {
                    internalType: "uint256",
                    name: "totalOriginalAdditionalRecipients",
                    type: "uint256",
                  },
                  {
                    components: [
                      {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                      },
                      {
                        internalType: "address payable",
                        name: "recipient",
                        type: "address",
                      },
                    ],
                    internalType: "struct AdditionalRecipient[]",
                    name: "additionalRecipients",
                    type: "tuple[]",
                  },
                  { internalType: "bytes", name: "signature", type: "bytes" },
                ],
                internalType: "struct BasicOrderParameters",
                name: "_basicOrderParameters",
                type: "tuple",
              },
              {
                internalType: "bytes",
                name: "_signatureFromBorrower",
                type: "bytes",
              },
              {
                internalType: "bytes",
                name: "_signatureFromLender",
                type: "bytes",
              },
            ],
            name: "executeUsingOffchainSignatures",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
            name: "executedSignatures",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "getMarketRegistry",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "getTellerV2",
            outputs: [
              { internalType: "contract TellerV2", name: "", type: "address" },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              { internalType: "uint256", name: "marketId", type: "uint256" },
            ],
            name: "getTellerV2MarketOwner",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                components: [
                  {
                    internalType: "address",
                    name: "borrower",
                    type: "address",
                  },
                  { internalType: "address", name: "lender", type: "address" },
                  {
                    internalType: "uint256",
                    name: "totalPurchasePrice",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "principal",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "downPayment",
                    type: "uint256",
                  },
                  { internalType: "uint32", name: "duration", type: "uint32" },
                  {
                    internalType: "uint32",
                    name: "signatureExpiration",
                    type: "uint32",
                  },
                  {
                    internalType: "uint16",
                    name: "interestRate",
                    type: "uint16",
                  },
                  {
                    internalType: "string",
                    name: "metadataURI",
                    type: "string",
                  },
                  {
                    internalType: "address",
                    name: "referralAddress",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "marketId",
                    type: "uint256",
                  },
                ],
                internalType: "struct BNPLMarket.SubmitBidArgs",
                name: "_submitBidArgs",
                type: "tuple",
              },
              {
                internalType: "address",
                name: "assetContractAddress",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "assetTokenId",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "assetQuantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "totalPurchasePrice",
                type: "uint256",
              },
              {
                internalType: "address",
                name: "paymentToken",
                type: "address",
              },
            ],
            name: "getTypeHash",
            outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
            stateMutability: "pure",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_cryptopunksMarketAddress",
                type: "address",
              },
              {
                internalType: "address",
                name: "_seaportEscrowBuyer",
                type: "address",
              },
              {
                internalType: "address",
                name: "_cryptopunksEscrowBuyer",
                type: "address",
              },
            ],
            name: "initialize",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "address", name: "forwarder", type: "address" },
            ],
            name: "isTrustedForwarder",
            outputs: [{ internalType: "bool", name: "", type: "bool" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "onUpgrade",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "bytes32",
                name: "domainSeparator",
                type: "bytes32",
              },
              { internalType: "bytes32", name: "typeHash", type: "bytes32" },
              { internalType: "bytes", name: "_signature", type: "bytes" },
            ],
            name: "recoverSignature",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "seaportEscrowBuyer",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              { internalType: "uint256", name: "_marketId", type: "uint256" },
              {
                internalType: "address",
                name: "_feeRecipient",
                type: "address",
              },
              { internalType: "bool", name: "_allowed", type: "bool" },
            ],
            name: "setAllowedFeeRecipient",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              { internalType: "uint256", name: "_marketId", type: "uint256" },
              { internalType: "uint16", name: "_pct", type: "uint16" },
            ],
            name: "setReferralRewardPercent",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "upgradedToVersion",
            outputs: [{ internalType: "string", name: "", type: "string" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "wethAddress",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "initialLogic",
                type: "address",
              },
              {
                internalType: "address",
                name: "initialAdmin",
                type: "address",
              },
              { internalType: "bytes", name: "_data", type: "bytes" },
            ],
            stateMutability: "payable",
            type: "constructor",
          },
        ],
      },
      USDC: {
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        abi: ERC20ABI,
      },
      WETH: {
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        abi: ERC20ABI,
      },
    },
  },
  // goerli
  5: {
    contracts: {
      USDC: {
        address: "0xd87ba7a50b2e7e660f678a895e4b72e7cb4ccd9c",
        abi: ERC20ABI,
      },
      WETH: {
        address: "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
        abi: ERC20ABI,
      },
    },
  },
  // polygon
  137: {
    contracts: {
      USDC: {
        address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
        abi: ERC20ABI,
      },
      WETH: {
        address: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
        abi: ERC20ABI,
      },
    },
  },
};
