import Spinner from "components/spinner";
import React from "react";
import { useParams } from "react-router-dom";
import CollectionHeader from "routes/collection/header";
import { useGetCollectionInfoQuery } from "slices/anplApiSlice";

import { CollectionItems } from "./CollectionItems";
import "./styles.scss";

export const Collection: React.FC = () => {
  const { slug } = useParams();
  const { data, isLoading } = useGetCollectionInfoQuery(slug!, {
    skip: !slug,
  });

  const collectionInfo = data?.result[0];

  return (
    <div className="collection">
      <Spinner showSpinner={isLoading} />
      {collectionInfo && (
        <>
          <CollectionHeader {...collectionInfo} />
          <CollectionItems />
        </>
      )}
    </div>
  );
};
