import AssetTile from "components/assetTile";
import SearchBar from "components/searchBar";
import Spinner from "components/spinner";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import {
  useGetCollectionAssetsQuery,
  useLazyGetSearchTokenByIdResultQuery,
} from "slices/anplApiSlice";
import { IAsset } from "types/IAsset";

export const CollectionItems: React.FC = ({}) => {
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [pageAssets, setPageAssets] = useState<IAsset[]>([]);
  const [searchResult, setSearchResult] = useState<IAsset[]>([]);
  const [searchString, setSearchString] = useState("");
  const [notFound, setNotFound] = useState(false);
  const { slug } = useParams();

  const resetCurrentStates = () => {
    setSearchResult([]);
    setNotFound(false);
    setHasNextPage(true);
  };

  const { data } = useGetCollectionAssetsQuery({
    slugOrAddress: slug!,
    cursor,
  });

  const [searchTokenById] = useLazyGetSearchTokenByIdResultQuery();

  const assets = data?.result;

  // concatenate assets as the user scrolls
  useEffect(() => {
    if (!assets) return;
    setPageAssets((state) => state.concat(assets));
  }, [assets]);

  // In the case that there are less assets than 1 page
  // setNextPage to false after data changes to avoid showing the loader after first query
  useEffect(() => {
    if (!data?.pagination?.hasNextPage) setHasNextPage(false);
  }, [data]);

  // Reset all states when changing collections from the search bar
  useEffect(() => {
    if (!slug) return;
    resetCurrentStates();
    setPageAssets([]);
    setSearchString("");
    setCursor("");
  }, [slug]);

  const loadNextPage = () => {
    setCursor(data?.pagination?.next ?? undefined);
  };

  const handleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearchString(value);
    if (value.length === 0) {
      resetCurrentStates();
      return;
    }
    searchTokenById({
      slugOrAddress: slug!,
      tokenIds: value,
    })
      .then((data) => {
        if (data.error || !data?.data?.success) {
          setNotFound(true);
          throw "Asset not found";
        } else if (data.data) {
          const result = data.data?.result;
          if (result?.length > 0) {
            setSearchResult(result);
            setHasNextPage(false);
            setNotFound(false);
          }
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const assetsToDisplay = searchResult.length > 0 ? searchResult : pageAssets;

  return (
    <>
      <SearchBar
        placeholder="Search by ID"
        onChange={handleChange}
        value={searchString}
      />
      {notFound ? (
        "Asset not found"
      ) : (
        <InfiniteScroll
          dataLength={assetsToDisplay.length}
          next={loadNextPage}
          hasMore={hasNextPage}
          loader={<Spinner />}
        >
          <div className="collection-items">
            {assetsToDisplay.map((asset) => (
              <AssetTile key={asset.tokenId} asset={asset} />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};
