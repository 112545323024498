import { Input, InputProps } from "components/input/Input";
import { Token, tokenData } from "components/tokenPrice/TokenPrice";
import { formatUnits } from "ethers/lib/utils";

interface TokenInput extends InputProps {
  token?: Token;
}

export const TokenInput: React.FC<TokenInput> = ({
  label,
  name,
  defaultValue = " ",
  token = Token.USDC,
  ...props
}) => {
  return (
    <Input
      label={label}
      name={name}
      endAdornment={tokenData[token]?.icon}
      defaultValue={
        +formatUnits(defaultValue.toString(), tokenData[token]?.decimals)
      }
      {...props}
    />
  );
};
