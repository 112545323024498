import { TermsView } from "constants/types/TermsViewTypes";

import { useMemo, useState } from "react";
import { IAssetWithID } from "slices/cartSlice";

import { EditTerms } from "./editTerms/EditTerms";
import { ViewTerms } from "./viewTerms/ViewTerms";

export interface CartItemProps {
  asset: IAssetWithID;
}

export interface CartItemComponentProps extends CartItemProps {
  setCartView: React.Dispatch<React.SetStateAction<TermsView>>;
}

export const CartItem: React.FC<CartItemProps> = ({ asset }) => {
  const [cartView, setCartView] = useState<TermsView>(TermsView.VIEW_TERMS);

  const renderObject = useMemo(
    () => ({
      [TermsView.VIEW_TERMS]: (
        <ViewTerms asset={asset} setCartView={setCartView} />
      ),
      [TermsView.EDIT_TERMS]: (
        <EditTerms asset={asset} setCartView={setCartView} />
      ),
    }),
    [asset, setCartView]
  );

  return asset && <>{renderObject[cartView]}</>;
};
