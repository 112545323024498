/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { BigNumber } from "ethers";
import { DateTime } from "luxon";
import { useAccount, useContract, useProvider, useQuery } from "wagmi";
import { useContracts } from "./useContracts";

const applicableBidStates = [3, 4, 5]; // accepted, repaid, or liquidated
const applicableMarkets = ["1", "6"]; // temporary while we test

export const today = () => DateTime.now();

export interface MyNFT {
  escrowedToken: {
    tokenId: string;
    contractAddress: string;
    tokenClaimed: boolean;
  };
  isDefaulted: boolean;
  nextPaymentDate: string;
  state: number;
  bidId: string;
  amountDue: string;
  tokenAddress: string;
}

export const useGetMyNfts = () => {
  const { address } = useAccount();
  const provider = useProvider();

  const contracts = useContracts();

  const tellerV2Contract = useContract({
    ...contracts.TellerV2,
    signerOrProvider: provider,
  });
  const bnplContract = useContract({
    ...contracts.BNPLMarket,
    signerOrProvider: provider,
  });

  const {
    data: myNFTsData,
    isLoading,
    refetch,
  } = useQuery(["myNFTs"], async () => {
    const loanIds: BigNumber[] = await tellerV2Contract?.getBorrowerLoanIds(
      address
    );

    const borrowerBids = await Promise.all(
      loanIds.map(async (loanId) => {
        return { ...(await tellerV2Contract?.bids(loanId)), loanId };
      })
    ).then((data) =>
      data?.filter(
        (bid: any) =>
          applicableBidStates.includes(+bid.state) &&
          applicableMarkets.includes(bid.marketplaceId.toString())
      )
    );

    const bidsWithAssetAndLoanData = await Promise.all(
      borrowerBids.map(async (bid) => {
        const bidId = bid.loanId.toString();
        const escrowedTokenData = await bnplContract?.escrowedTokensForLoan(
          bidId
        );
        const escrowedToken = {
          tokenId: escrowedTokenData.tokenId?.toString(),
          contractAddress: escrowedTokenData?.tokenAddress,
          tokenClaimed: escrowedTokenData?.tokenClaimed,
        };
        if (escrowedToken?.tokenClaimed) return; // return null if token has already been claimed
        const nextPaymentDateInSeconds =
          await tellerV2Contract?.calculateNextDueDate(bidId);
        const {
          principal: outstandingPrincipal,
          interest: outstandingInterest,
        } = await tellerV2Contract?.["calculateAmountDue(uint256,uint256)"]?.(
          bidId,
          nextPaymentDateInSeconds
        );

        const amountDue = BigNumber.from(outstandingPrincipal)
          .add(BigNumber.from(outstandingInterest))
          .toString();

        const nextPaymentDate = DateTime.fromSeconds(+nextPaymentDateInSeconds);

        const isLate = bid?.state !== 4 && today() > nextPaymentDate;

        const isDefaulted = await tellerV2Contract?.isLoanDefaulted(bidId);
        return {
          state: isLate ? 6 : bid?.state, // custom state for late loans for displaying status
          bidId: bid?.loanId.toString(),
          escrowedToken,
          nextPaymentDate: nextPaymentDate.toFormat("LLL dd, yyyy"),
          amountDue,
          isDefaulted,
          tokenAddress: bid?.loanDetails?.lendingToken,
        };
      })
    );

    return bidsWithAssetAndLoanData.filter((bid) => !!bid) as MyNFT[]; // filter out claimed NFTs
  });

  return { data: myNFTsData, isLoading, refetch };
};
