import { useMemo } from "react";
import { useContractRead } from "wagmi";

import { useContracts } from "./useContracts";

export const useGetFees = () => {
  const contracts = useContracts();
  const marketFeeData = useContractRead({
    ...contracts.MarketRegistry,
    functionName: "getMarketplaceFee",
    args: ["6"],
    staleTime: Infinity,
  });

  const protocolFeeData = useContractRead({
    ...contracts.TellerV2,
    functionName: "protocolFee",
    staleTime: Infinity,
  });

  const marketFee = (marketFeeData.data as number) ?? 0;
  const protocolFee = (protocolFeeData.data as number) ?? 0;

  return useMemo(() => ({ marketFee, protocolFee }), [marketFee, protocolFee]);
};
