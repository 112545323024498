import { HOME_ROUTE } from "constants/routes";

import { ReactComponent as HeaderLogo } from "assets/header_logo.svg";
import NavigationIcons from "components/navigationIcons";
import {
  DesktopComponent,
  MobileComponent,
} from "components/responsiveComponents";
import { useNavigate } from "react-router-dom";

import CartButton from "./cartButton";
import "./styles.scss";
import WalletButton from "./walletButton/";

export const HeaderNavigation: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="anpl-nav">
      <MobileComponent>
        <WalletButton />
        <HeaderLogo
          data-test="mobile-logo"
          height="1em"
          onClick={() => navigate(HOME_ROUTE)}
          className="clickable-icon"
        />
        <CartButton />
      </MobileComponent>
      <DesktopComponent>
        <a
          href="https://usdc.homes/"
          target="_blank"
          rel="noreferrer"
          className="learn-more-link"
        >
          Learn More
        </a>
        <HeaderLogo
          data-test="mobile-logo"
          onClick={() => navigate(HOME_ROUTE)}
          className="clickable-icon"
        />
        <div className="row">
          <WalletButton />
          <NavigationIcons />
        </div>
      </DesktopComponent>
    </div>
  );
};
