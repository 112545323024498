import { CHECKOUT_ROUTE } from "constants/routes";

import { RootState } from "app/store";
import Button from "components/button";
import { useIsMobile } from "hooks/useIsMobile";
import { useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import "./index.scss";

export const GoToCartButton = () => {
  const { ids } = useSelector((state: RootState) => state.cartReducer);
  const navigate = useNavigate();
  const isCheckout = useMatch(CHECKOUT_ROUTE);
  const isMobile = useIsMobile();
  return isMobile && !isCheckout && ids.length > 0 ? (
    <Button
      label={`Go to cart (${ids.length})`}
      className="go-to-cart-button"
      onClick={() => navigate(CHECKOUT_ROUTE)}
    />
  ) : null;
};
