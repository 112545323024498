import { Address } from "@wagmi/core";
import { ethers } from "ethers";

import { useContracts } from "./useContracts";

export const useTokenAddress = (
  tokenAddress?: Address
): Address | undefined => {
  const contracts = useContracts();

  if (tokenAddress === ethers.constants.AddressZero) {
    // eslint-disable-next-line no-param-reassign
    tokenAddress = contracts.WETH?.address;
  }

  return tokenAddress ? ethers.utils.getAddress(tokenAddress) : undefined;
};
