import loader from "assets/loader.svg";
import "./styles.scss";

interface SpinnerProps {
  showSpinner?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({ showSpinner = true }) => (
  <>
    {showSpinner && (
      <div className="usdc-homes-loader">
        <img src={loader} alt="spinner" className="spinner" />
      </div>
    )}
  </>
);
