import { useConvertETHToUSD } from "hooks/useConvertETHToUSD";

import EthPrice from "../tokenPrice";
import { EthPriceProps } from "../tokenPrice/TokenPrice";

type EthPriceWithUsdProps = EthPriceProps;

export const EthPriceWithUsd: React.FC<EthPriceWithUsdProps> = ({
  price,
  className = "row",
  ...props
}) => {
  const { ethAmountInUSD } = useConvertETHToUSD(price);
  return (
    <div className={className}>
      <EthPrice price={price} {...props} />
      <span className="secondary-text">({ethAmountInUSD})</span>
    </div>
  );
};
