import { AddressType } from "constants/types/AddressType";

import { EmailInfo } from "components/emailInfo/EmailInfo";
import EthPriceWithUsd from "components/ethPriceWithUsd";
import InfoSection from "components/infoSection";
import Section from "components/section";
import Spinner from "components/spinner";
import { BigNumber } from "ethers";
import { useGetLoans } from "hooks/useGetLoans";
import { useMemo } from "react";

import { LoansTable } from "./LoansTable";

import "./styles.scss";

const defaultAggregates = {
  totalLiquidityProvided: BigNumber.from(0),
  totalEarningsToDate: BigNumber.from(0),
};

export const ManageLoans: React.FC = () => {
  const { loans, showSpinner } = useGetLoans(AddressType.lender);
  const aggregateValues = useMemo(
    () =>
      loans.reduce(
        (prev, curr) => ({
          totalLiquidityProvided: prev.totalLiquidityProvided.add(
            BigNumber.from(curr.principal)
          ),
          totalEarningsToDate: prev.totalEarningsToDate.add(
            BigNumber.from(curr.interestPaid)
          ),
        }),
        defaultAggregates
      ),
    [loans]
  );
  return (
    <div className="manage-loans-page dynamic-height">
      <h2>Loan summary</h2>
      <Spinner showSpinner={showSpinner} />
      {loans.length > 0 && (
        <>
          <Section sectionTitle="Total" className="totals">
            <div className="row">
              <InfoSection
                label="Liquidity provided"
                content={
                  <EthPriceWithUsd
                    price={aggregateValues.totalLiquidityProvided.toString()}
                  />
                }
              />
              <InfoSection
                label="Earnings to date"
                content={
                  <EthPriceWithUsd
                    price={aggregateValues.totalEarningsToDate.toString()}
                  />
                }
              />
            </div>
          </Section>
          <LoansTable loans={loans} />
          <EmailInfo />
        </>
      )}
    </div>
  );
};
