import { ReactComponent as Refresh } from "assets/refresh.svg";
import Button from "components/button";
import Input from "components/input";
import Section from "components/section";
import { TokenInput } from "components/TokenInput/TokenInput";
import TokenPrice from "components/tokenPrice";
import { BigNumber } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { useAppDispatch } from "hooks";
import React, { useContext, useEffect, useState } from "react";
import { addUserOfferToCart } from "slices/cartSlice";
import { UserSubmitedTerms } from "types/IAsset";
import { SIZES } from "types/sizes";
import {
  calculateTotalPayLaterAndBorrowerPrincipal,
  convertOfferTerms,
} from "utils/offerUtils";

import InfoSection from "../../../components/infoSection";

import {
  DesktopComponent,
  MobileComponent,
} from "components/responsiveComponents";
import { Token, tokenData } from "components/tokenPrice/TokenPrice";
import { AssetContext } from "../AssetPage";
import { DesktopOfferTermsButton } from "./DesktopOfferTermsButton.tsx";
import { OfferTermsComponent } from "./OfferTerms";
import { useGetFees } from "hooks/useGetFees";

interface EditOfferTermsProps extends OfferTermsComponent {
  setLastOfferKey: React.Dispatch<React.SetStateAction<string>>;
}

const { USDC } = Token;

export const EditOfferTerms: React.FC<EditOfferTermsProps> = ({
  setOffersView,
  asset,
  setLastOfferKey,
}) => {
  const recommendedTerms = convertOfferTerms(asset.bestOffer.submitBidArgs);

  const { symbol } = useContext(AssetContext);
  const { marketFee, protocolFee } = useGetFees();

  const [payLater, setPayLater] = useState(
    recommendedTerms?.totalPayLater ?? "0"
  );
  const [requiredPrincipal, setRequiredPrincipal] = useState(
    recommendedTerms?.principal
  );
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [resetFields, setResetFields] = useState(false);
  const dispatch = useAppDispatch();
  // TODO fix this - consideration token not being returned if there are no offers
  // const { data: tokenData } = useToken({
  //   address: asset.basicOrderParams.considerationToken as Address,
  // });

  useEffect(() => {
    setResetFields(false);
  }, []);

  const { buyNowPriceRaw } = asset;
  const buyNowPriceBN = BigNumber.from(buyNowPriceRaw ?? 0);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const submitedTerms = Object.fromEntries(formData) as UserSubmitedTerms;
    submitedTerms.downPayment = parseUnits(
      submitedTerms.downPayment,
      tokenData[symbol].decimals
    ).toString();
    submitedTerms.totalPayLater = payLater;
    submitedTerms.principal = requiredPrincipal;
    const offerTerms = { ...asset.bestOffer.submitBidArgs, ...submitedTerms };
    const id = JSON.stringify(submitedTerms);
    setLastOfferKey(id);
    dispatch(addUserOfferToCart({ ...asset, offerTerms, id }));
    // setTimeout(() => setOffersView(), 1500);
    setOffersView();
  };

  const handleFormBlur = (e: React.FormEvent<HTMLFormElement>) =>
    setIsFormInvalid(!e.currentTarget.checkValidity());

  const handleReset = () => {
    setPayLater(recommendedTerms?.totalPayLater ?? "0");
    setIsFormInvalid(false);
    setResetFields(true);
  };

  const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.name === "accept-tnc") {
      return;
    }
    const formData = new FormData(e.currentTarget);
    const submitedTerms = Object.fromEntries(formData) as UserSubmitedTerms;

    let principal = BigNumber.from(0);
    let apr = 0;

    if (target.name === "downPayment") {
      principal = buyNowPriceBN.sub(
        parseUnits(target.value || "0", tokenData[symbol].decimals)
      );
      apr = +submitedTerms.interestRate;
    }

    if (target.name === "interestRate") {
      apr = +target.value;
      principal = buyNowPriceBN.sub(
        parseUnits(submitedTerms.downPayment || "0", tokenData[symbol].decimals)
      );
    }

    const { borrowerPrincipal: newPrincipal } =
      calculateTotalPayLaterAndBorrowerPrincipal(
        principal,
        apr * 100,
        marketFee,
        protocolFee
      );
    setPayLater(newPrincipal.gte(0) ? newPrincipal.toString() : "0");
    setRequiredPrincipal(newPrincipal.toString());
  };
  return (
    <form
      onSubmit={handleFormSubmit}
      className="edit-terms"
      onBlur={handleFormBlur}
      onChange={handleFormChange}
    >
      <Section
        sectionTitle="Offer Terms"
        sectionTitleEnd={
          <MobileComponent>
            <button
              type="reset"
              className="clickable-icon reset-button"
              onClick={handleReset}
            >
              <Refresh />
            </button>
          </MobileComponent>
        }
      >
        <DesktopComponent>
          <div className="secondary-text">
            Fill in your own terms but make it competitive by looking at the
            terms others have offerred below.
          </div>
        </DesktopComponent>
        <div className="inputs">
          <div className="row pay-later">
            <TokenInput
              label="Down payment"
              name="downPayment"
              defaultValue={recommendedTerms?.downPayment}
              type="number"
              min={0}
              max={formatUnits(buyNowPriceBN, tokenData[symbol].decimals)}
              required
              step={0.001}
              resetField={resetFields}
              token={symbol}
            />
            <MobileComponent>
              <Button
                label="Add to cart"
                size={SIZES.COMPRESSED}
                type="submit"
                animateOnClick={!isFormInvalid}
                animationText="Added!"
              />
            </MobileComponent>
          </div>
          <div className="row loan-amount">
            <InfoSection
              content={<TokenPrice price={payLater} token={symbol} />}
              label="Loan amount"
            />
          </div>
          <div className="row terms">
            <Input
              label="Payment duration"
              endAdornment="months"
              defaultValue={recommendedTerms?.duration}
              name="duration"
              min={0}
              type="number"
              required
              resetField={resetFields}
              className="flex-2"
            />
            <Input
              label="APR"
              endAdornment="%"
              defaultValue={recommendedTerms?.interestRate}
              name="interestRate"
              min={0}
              type="number"
              required
              resetField={resetFields}
              className="flex-1"
            />
            <Input
              label="Offer expires"
              endAdornment="days"
              defaultValue={recommendedTerms?.signatureExpiration ?? " "}
              name="signatureExpiration"
              min={0}
              type="number"
              required
              resetField={resetFields}
              className="flex-2"
            />
          </div>
        </div>
        <DesktopOfferTermsButton label="Modify Terms" />
      </Section>
    </form>
  );
};
