import { BigNumber } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { DateTime } from "luxon";
import { UserSubmitedTerms } from "types/IAsset";

import { amountFormatter } from "./currencyUtils";

export const calculatePaymentsFromOffer = (
  submitBidArgs: UserSubmitedTerms
) => {
  const { duration, totalPayLater } = submitBidArgs;

  const totalPayLaterBN = BigNumber.from(totalPayLater);

  const paymentAmount = totalPayLaterBN.div(submitBidArgs.duration);

  return Array.from({ length: +duration }).map((_, i) => {
    return {
      totalPaidToDate: totalPayLaterBN.add(paymentAmount.mul(i)).toString(),
      paymentAmount: paymentAmount.toString(),
      date: DateTime.now()
        .plus({ days: i * 30 })
        .setLocale("en-us")
        .toLocaleString(),
    };
  });
};

export const createPaymentDurationString = (
  principal: string,
  duration: string,
  isCumulative = false
) =>
  `(+${amountFormatter.format(+formatEther(principal))} ETH${
    isCumulative ? " every month" : " "
  } for ${duration} months)`;
