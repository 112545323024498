import cx from "classnames";
import React from "react";
import { ComponentWithChildren, ComponentWithClassName } from "types/Component";

import "./styles.scss";

interface TableProps extends ComponentWithChildren, ComponentWithClassName {}

interface TableComposition {
  Row: React.FC<RowProps>;
  HeaderRow: React.FC<RowProps>;
}

interface RowProps extends ComponentWithChildren, ComponentWithClassName {}

const Table: React.FC<TableProps> & TableComposition = ({
  children,
  className,
}) => {
  return <div className={cx("table", className)}>{children}</div>;
};

const Row: React.FC<RowProps> = ({ className, children }) => (
  <div className={cx("row", className)}>
    {React.Children.map(children, (child) => (
      <div className="cell">{child}</div>
    ))}
  </div>
);

const HeaderRow: React.FC<RowProps> = ({ className, children }) => (
  <Row className={cx("table-header", className)}>{children}</Row>
);

Table.Row = Row;
Table.HeaderRow = HeaderRow;

export { Table };
