import { DomainData } from "@clarity-credit/anpl-sdk/types/types";
import { useContractRead, useNetwork } from "wagmi";

import { useContracts } from "./useContracts";

export const useGetDomainData = (): DomainData => {
  const contracts = useContracts();
  const { chain } = useNetwork();

  const name = useContractRead({
    ...contracts.BNPLMarket,
    functionName: "CONTRACT_NAME",
  });
  const version = useContractRead({
    ...contracts.BNPLMarket,
    functionName: "CONTRACT_VERSION",
  });

  return {
    // @ts-ignore
    name: name.data ?? "",
    // @ts-ignore
    version: version.data ?? "",
    chainId: chain?.id ?? 0,
    // @ts-ignore
    verifyingContract: contracts.BNPLMarket?.address ?? "",
  };
};
