import { ReactComponent as Close } from "assets/close.svg";
import { EmailInfo } from "components/emailInfo/EmailInfo";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

export const Settings: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="settings-page">
      <div className="row">
        <h2>Settings</h2>
        <Close onClick={() => navigate(-1)} className="clickable-icon" />
      </div>
      <EmailInfo show />
      <div className="legal-section">
        <div className="secondary-text"> Legal:</div>
        <div className="links">
          <a href="">Privacy Policy</a>
          <a href="">Terms and conditions</a>
          <a href="">User agreement</a>
        </div>
      </div>
    </div>
  );
};
