export enum RoofstockTraits {
  "Property Type" = "Property Type",
  "Bedrooms" = "Bedrooms",
  "Bathrooms" = "Bathrooms",
  "Year Built" = "Year Built",
  "Home Size Sq Ft" = "Home Size Sq Ft",
  "Lot Size Sq Ft" = "Lot Size Sq Ft",
  "Market" = "Market",
  "City" = "City",
  "State" = "State",
}

export enum RoofstockPropertyTypes {
  House = "House",
  Condo = "Condo",
  Apartment = "Apartment",
  Commercial = "Commercial",
}
