import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useEffect } from "react";
import { useAccount } from "wagmi";

export const useGetUserWallet = () => {
  const { address } = useAccount();
  const { openConnectModal } = useConnectModal();

  useEffect(() => {
    !address && openConnectModal?.();
  }, [address, openConnectModal]);

  return { address };
};
