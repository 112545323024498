import { ReactComponent as Search } from "assets/search.svg";
import cx from "classnames";
import { ComponentWithClassName } from "types/Component";
import { SIZES } from "types/sizes";

import "./styles.scss";

interface SearchProps
  extends React.HTMLProps<HTMLInputElement>,
    ComponentWithClassName {
  placeholder?: string;
  value?: string;
}

export const SearchBar: React.FC<SearchProps> = ({
  placeholder = "search",
  size = SIZES.STANDARD,
  className,
  onChange,
  value,
  onBlur,
}) => (
  <div className={cx("search-bar", size, className)}>
    <Search />
    <input
      placeholder={placeholder}
      data-test="search-input"
      onChange={onChange}
      value={value}
      onBlur={onBlur}
    />
  </div>
);
