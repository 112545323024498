import teller_contracts from "@teller-protocol/v2-contracts/build/hardhat/contracts.json";
import { Abi } from "abitype";
import external_contracts from "utils/externalContracts";
import { Address, Chain, chainId, useNetwork } from "wagmi";

type IContractConfig = { address: Address; abi: Abi };
type IContractConfigByName = Record<string, IContractConfig>;
type IContractConfigByNetworkId = Record<
  number | string,
  {
    contracts: IContractConfigByName;
  }
>;

const getContractsFromConfig = (
  config: IContractConfigByNetworkId,
  chain?: Chain
) => {
  return chain
    ? config[chain.id]?.contracts
    : config[chainId.mainnet]?.contracts;
};

export const useContracts = (): IContractConfigByName => {
  const { chain } = useNetwork();

  return {
    ...getContractsFromConfig(
      teller_contracts as IContractConfigByNetworkId,
      chain
    ),
    ...getContractsFromConfig(
      external_contracts as IContractConfigByNetworkId,
      chain
    ),
  };
};
