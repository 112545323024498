import { Address } from "@wagmi/core";
import { useAccount, useNetwork, useContractRead, erc20ABI } from "wagmi";

import { useTokenAddress } from "./useTokenAddress";

export const useTokenAllowance = (
  tokenAddress?: Address,
  spenderAddress?: Address
) => {
  const { chain } = useNetwork();
  const { address: userAddress } = useAccount();
  // eslint-disable-next-line no-param-reassign
  tokenAddress = useTokenAddress(tokenAddress);

  const result = useContractRead({
    enabled: !!tokenAddress && !!spenderAddress && !!userAddress,
    address: tokenAddress,
    abi: erc20ABI,
    functionName: "allowance",
    args: [userAddress!, spenderAddress!],
    watch: true,
  });
  return result;
};
