import "./styles.scss";

const imageURLS = [
  "https://img.seadn.io/files/32d60c14ee6393483c6cc326c48f6b63.png?fit=max&w=600",
  "https://img.seadn.io/files/da6faabfa63c764c96be24e0748d2384.png?fit=max&w=600",
  "https://img.seadn.io/files/d92cf5b886c88736430166bfc6a680e1.png?fit=max&w=600",
  "https://img.seadn.io/files/3fd0a844cd8f0004c145cd6fcb1ce698.png?fit=max&w=600",
  "https://img.seadn.io/files/08a61186d6ed9db35d46a61862bec195.png?fit=max&w=600",
  "https://img.seadn.io/files/2a14857a6de81f0cdac4156295ad152f.png?fit=max&w=1000",
  "https://img.seadn.io/files/2cb31386e47be1cde3465e9939a6198e.png?fit=max&w=1000",
  "https://img.seadn.io/files/861973e934723fa3841b56379f6c13fe.png?fit=max&w=1000",
  "https://img.seadn.io/files/f1d52c1c4da157dbace4541e2af3a19c.png?fit=max&w=1000",
  "https://img.seadn.io/files/6f9d98126697df92101ac6c93aca39a6.png?fit=max&w=1000",
];

const nfts = Array.from({ length: 20 }, () => ({
  url: imageURLS[Math.floor(Math.random() * 9)],
}));

export const NFTBackground: React.FC = () => {
  return (
    <div className="nft-background">
      {nfts.map((nft, index) => (
        <img src={nft.url} key={index} />
      ))}
    </div>
  );
};
