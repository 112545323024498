import { LoanStatus } from "constants/types/LoanStatus";

import AssetBasicInfo from "components/assetBasicInfo";
import Button from "components/button";
import EthPrice from "components/tokenPrice";
import Section from "components/section";
import Table from "components/table";
import WalletLink from "components/walletLink";
import { FC } from "react";
import { Loan } from "types/Loan";
import { SIZES } from "types/sizes";
import { calculateDaysSince } from "utils/dateUtils";
import { useAccount } from "wagmi";

interface LoansTableProps {
  loans: Loan[];
}

const renderStatus = (loan: Loan) => {
  const { lastPaymentAt } = loan;
  const isDefault = loan.tellerV2loanStatus === LoanStatus.default;
  const isPaid = loan.tellerV2loanStatus === LoanStatus.paid;
  const isDueSoon = calculateDaysSince(lastPaymentAt) > 25;
  if (isPaid) return <div className="status paid">Paid</div>;
  if (isDefault) return <Button label="Liquidate" size={SIZES.COMPRESSED} />;
  if (isDueSoon) return <div className="status due-soon">Due soon</div>;
  else return <div className="status on-time">Paid on time</div>;
};

const calculatePercentPaid = (loan: Loan) =>
  Math.floor((+loan.amountPaid / +loan.principal) * 100);

export const LoansTable: FC<LoansTableProps> = ({ loans }) => {
  const { address } = useAccount();
  return (
    <Section
      sectionTitle={`Active Loans (${loans.length})`}
      className="loans-table"
      sectionTitleEnd={
        <a
          href={`https://app.teller.org/ethereum/profile/${address}/lender`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Past loans in teller {">"}
        </a>
      }
    >
      <Table>
        <Table.HeaderRow>
          <div className="left-aligned">NFT Info</div>
          <div className="left-aligned">Borrower address</div>
          <div className="right-aligned">ID</div>
          <div className="right-aligned">Loan amount</div>
          <div className="right-aligned">APR</div>
          <div className="right-aligned">% paid</div>
          <div className="right-aligned">Interest earned</div>
          <div className="left-aligned">Status</div>
        </Table.HeaderRow>
        {loans.map((loan, index) => (
          <Table.Row key={index}>
            <div className="left-aligned assets">
              <img src={loan.assets[0].alchemyImageUri} />
              <AssetBasicInfo asset={loan.assets[0]} />
            </div>
            <div className="left-aligned">
              <WalletLink address={loan.borrower} />
            </div>
            <div className="right-aligned">{loan.bidId}</div>
            <div className="right-aligned">
              <EthPrice price={loan.principal} />
            </div>
            <div className="right-aligned">{+loan.interestRate / 100}%</div>
            <div className="right-aligned">{calculatePercentPaid(loan)}%</div>
            <div className="right-aligned">
              <EthPrice price={loan.interestPaid} />
            </div>
            <div className="center-aligned">{renderStatus(loan)}</div>
          </Table.Row>
        ))}
      </Table>
    </Section>
  );
};
