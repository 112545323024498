import "@rainbow-me/rainbowkit/styles.css";
import { UseMutationOptions } from "@tanstack/react-query";
import { Address } from "@wagmi/core";
import { Abi } from "abitype";
import { ethers } from "ethers";
import { useContracts } from "hooks/useContracts";
import { useMemo } from "react";
import {
  erc20ABI,
  useContractWrite,
  usePrepareContractWrite,
  useToken,
  useWaitForTransaction,
} from "wagmi";

// example use:

// const {
//   write: approveWeth,
//   isLoading,
//   isSuccess,
//   isError,
//   txHash,
//   response,
// } = useTransaction(SupportedTokensEnum.WETH, 'approve', [
//   '0x84a04d7Edc39c83E46eCA735dE435873B65E11a8',
//   100,
// ])
// const onClick = () => approveWeth?.()

interface IContractConfig {
  address: Address;
  abi: Abi;
}

const useContractConfig = (
  nameOrAddress: string | Address
): IContractConfig => {
  const contracts = useContracts();
  const token = useToken({
    address: nameOrAddress as Address,
    enabled: ethers.utils.isAddress(nameOrAddress),
  });

  let contractConfig = contracts[nameOrAddress];
  if (!contractConfig) {
    if (token.data?.address) {
      contractConfig = {
        address: token.data.address,
        abi: erc20ABI,
      };
    }
  }

  return contractConfig;
};

type IUseTransactionConfig = Pick<
  UseMutationOptions,
  "onError" | "onSuccess" | "onSettled"
> & {
  enabled?: boolean;
};

export const useTransaction = (
  contractNameOrAddress: string | Address,
  functionName: string,
  args: any[],
  _config: IUseTransactionConfig = {}
) => {
  const config = Object.assign({}, { enabled: true }, _config);

  const contractConfig = useContractConfig(contractNameOrAddress);

  const prepareWrite = usePrepareContractWrite({
    ...contractConfig,
    functionName,
    args,
    enabled: config.enabled,
  });

  const { data, write, writeAsync } = useContractWrite(prepareWrite.config);

  // Hook does not run unless hash is defined.
  const {
    isLoading,
    isSuccess,
    isError,
    data: response,
    error,
  } = useWaitForTransaction({
    hash: data?.hash,
  });

  return useMemo(() => {
    return {
      write,
      writeAsync,
      isLoading,
      isSuccess,
      isError,
      txHash: data?.hash,
      response,
      error,
      prepareWrite,
    };
  }, [
    write,
    writeAsync,
    isLoading,
    isSuccess,
    isError,
    data?.hash,
    response,
    error,
    prepareWrite,
  ]);
};
