import {
  ASSET_ROUTE,
  CHECKOUT_ROUTE,
  COLLECTION_ROUTE,
  HOME_ROUTE,
  MANAGE_LOANS_ROUTE,
  MY_NFTS_ROUTE,
  PENDING_REQUESTS_ROUTE,
  SEARCH_ROUTE,
  SETTINGS_ROUTE,
  SUBMITTED_ROUTE,
} from "constants/routes";

import { App, AppPage } from "app/App";
import { createBrowserRouter } from "react-router-dom";
import AssetPage from "routes/assetPage";
import Checkout from "routes/checkout";
import Collection from "routes/collection";
import ManageLoans from "routes/manageLoans";
import MyNFTs from "routes/myNFTs";
import NotFound from "routes/notFound";
import PendingRequests from "routes/pendingRequests";
import SearchAndExplore from "routes/searchAndExplore";
import SearchPage from "routes/searchPage";
import Settings from "routes/settings";
import SubmittedPage from "routes/submittedPage";

export const router = createBrowserRouter([
  {
    element: <App />,
    path: HOME_ROUTE,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <SearchAndExplore />,
      },
      {
        element: <AppPage />,
        children: [
          {
            path: COLLECTION_ROUTE,
            element: <Collection />,
          },
          {
            path: SEARCH_ROUTE,
            element: <SearchPage />,
          },
          {
            path: ASSET_ROUTE,
            element: <AssetPage />,
          },
          {
            path: CHECKOUT_ROUTE,
            element: <Checkout />,
          },
          {
            path: SUBMITTED_ROUTE,
            element: <SubmittedPage />,
          },
          {
            path: PENDING_REQUESTS_ROUTE,
            element: <PendingRequests />,
          },
          {
            path: MY_NFTS_ROUTE,
            element: <MyNFTs />,
          },
          {
            path: MANAGE_LOANS_ROUTE,
            element: <ManageLoans />,
          },
          {
            path: SETTINGS_ROUTE,
            element: <Settings />,
          },
        ],
      },
    ],
  },
]);
