import { ReactComponent as Eth } from "assets/eth.svg";
import { ReactComponent as USDC } from "assets/usdc.svg";
import cx from "classnames";
import { formatUnits } from "ethers/lib/utils";
import { ComponentWithClassName } from "types/Component";
import { amountFormatter } from "utils/currencyUtils";
import "./styles.scss";

export enum Token {
  "ETH" = "ETH",
  "WETH" = "WETH",
  "USDC" = "USDC",
}

// TODO Make all of this dynamic
export const tokenData = {
  [Token.ETH]: { icon: <Eth />, decimals: 18 },
  [Token.WETH]: { icon: <Eth />, decimals: 18 },
  [Token.USDC]: { icon: <USDC width="14px" />, decimals: 6 },
};

export interface EthPriceProps extends ComponentWithClassName {
  price: string;
  dataTest?: string;
  showPlusSign?: boolean;
  token?: Token;
  iconFirst?: boolean;
  hideIcon?: boolean;
}

export const TokenPrice: React.FC<EthPriceProps> = ({
  price = "0",
  dataTest,
  className,
  showPlusSign,
  token = Token.USDC,
  iconFirst,
  hideIcon,
}) => {
  return (
    <div className={cx("token-price", className, iconFirst && "icon-first")}>
      <span data-test={dataTest} className="amount">
        {showPlusSign && "+"}
        {amountFormatter.format(
          +formatUnits(price, tokenData[token]?.decimals)
        )}
      </span>
      {!hideIcon && tokenData[token]?.icon}
    </div>
  );
};
