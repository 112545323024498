import { APE_NOW_FEE, PERCENTAGE_FACTOR } from "constants/fees";
import { DEFAULT_APR, DEFAULT_DURATION } from "constants/offers";

import { SubmitBidArgs } from "@clarity-credit/anpl-sdk/types/types";
import { BigNumber, ethers } from "ethers";
import { IAsset, IOffer, SubmitBidArgsWithTotalPayLater } from "types/IAsset";

import { ANPL, USDC_HOMES } from "constants/marketIds";
import {
  calculateDaysLeft,
  convertDaysLeftToDate,
  convertDurationToMonths,
  convertMonthsToSeconds,
  today,
} from "./dateUtils";

/*
  Turns user inputted OfferTerms to the data required by smart contract.
*/
export const prepareOfferTerms = (
  offerTerms: SubmitBidArgsWithTotalPayLater,
  borrower: string,
  isUSDCHomes = false
): SubmitBidArgs => {
  const durationInSeconds = convertMonthsToSeconds(offerTerms.duration);
  const expirationDate = convertDaysLeftToDate(
    offerTerms.signatureExpiration ?? " "
  );

  const { totalPayLater, ...termsWithoutTotalPayLater } = offerTerms;

  return {
    ...termsWithoutTotalPayLater,
    marketId: isUSDCHomes ? USDC_HOMES : ANPL,
    signatureExpiration: expirationDate.toString(),
    duration: durationInSeconds.toString(),
    interestRate: (+offerTerms.interestRate * 100).toFixed(0),
    metadataURI: "ipfs://",
    borrower,
  };
};

/**
   Turns data to human readable format.
 */
export const convertOfferTerms = (
  offerTerms: SubmitBidArgsWithTotalPayLater
) => {
  const durationInMonths = convertDurationToMonths(offerTerms.duration);
  const daysLeftForExpiration = calculateDaysLeft(
    offerTerms.signatureExpiration ?? " "
  );
  const interestRate = (+offerTerms.interestRate / 100).toString();

  return {
    ...offerTerms,
    interestRate,
    duration: durationInMonths.toString(),
    signatureExpiration: daysLeftForExpiration.toString(),
  };
};

export const generateOfferString = (offers: number) => {
  let offerString = "offer";
  if (offers > 1) offerString += "s";
  return offerString;
};

export const calculatePrincipalWithFee = (principal: BigNumber) => {
  const principalWithFee = principal.mul(APE_NOW_FEE).div(100);
  return principalWithFee;
};

export function calculateBorrowerPayoutFromLoanPrincipal(
  loanPrincipal: BigNumber,
  marketFeePct: BigNumber,
  protocolFeePct: BigNumber
): BigNumber {
  const marketFee = loanPrincipal.mul(marketFeePct).div(PERCENTAGE_FACTOR);

  const protocolFee = loanPrincipal.mul(protocolFeePct).div(PERCENTAGE_FACTOR);

  const borrowerPayout = loanPrincipal.sub(marketFee).sub(protocolFee);

  return borrowerPayout;
}

export function calculatePrincipalRequiredForBorrowerPayout( // THIS IS THE PRINCIPAL TO SEND
  expectedBorrowerPayment: BigNumber, // 5000   ///amount of wei THE BORROWER must get out of this loan
  marketFeePct: BigNumber, // 500
  protocolFeePct: BigNumber // 5
): BigNumber {
  const totalFeesPct = marketFeePct.add(protocolFeePct);

  const principalRequired = expectedBorrowerPayment
    .mul(BigNumber.from(PERCENTAGE_FACTOR))
    .div(BigNumber.from(PERCENTAGE_FACTOR).sub(totalFeesPct)); // rounding UP

  // ---- If we are off by 1,  subtract 1
  const calculatedBorrowerPayment = calculateBorrowerPayoutFromLoanPrincipal(
    principalRequired,
    marketFeePct,
    protocolFeePct
  );
  const offByError = calculatedBorrowerPayment.sub(expectedBorrowerPayment);

  return principalRequired.sub(offByError);
}

export const calculateTotalPayLaterAndBorrowerPrincipal = (
  principal: BigNumber,
  APR: number,
  marketFee: number,
  protocolFee: number
) => {
  const borrowerPrincipal = calculatePrincipalRequiredForBorrowerPayout(
    principal,
    BigNumber.from(marketFee),
    BigNumber.from(protocolFee)
  );
  return {
    totalPayLater: borrowerPrincipal
      .mul(PERCENTAGE_FACTOR + APR)
      .div(PERCENTAGE_FACTOR),
    borrowerPrincipal,
  };
};

export const calculateRecommendedOffer = (
  asset: Omit<IAsset, "offers">,
  marketFee: number,
  protocolFee: number
) => {
  const signatureExpiration = today()
    .plus({ days: 14 })
    .toUnixInteger()
    .toString();
  const { borrowerPrincipal, totalPayLater } =
    calculateTotalPayLaterAndBorrowerPrincipal(
      BigNumber.from(asset.buyNowPriceRaw ?? 0).sub(
        asset.suggestedDownPayment ?? 0
      ),
      DEFAULT_APR,
      marketFee,
      protocolFee
    );
  const rec = {
    id: "",
    submitBidArgs: {
      totalPurchasePrice: asset.buyNowPriceRaw ?? 0,
      downPayment: asset.suggestedDownPayment ?? 0,
      principal: borrowerPrincipal.toString(),
      totalPayLater: totalPayLater.toString(),
      duration: DEFAULT_DURATION.toString(),
      interestRate: DEFAULT_APR.toString(),
      metadataURI: "ipfs://",
      signatureExpiration,
      referralAddress: ethers.constants.AddressZero,
      marketId: "1",
    },
  };
  const offerId = JSON.stringify(rec);
  rec.id = offerId;
  return rec;
};

export const addTotalPayLaterToBestOffer = (
  bestOffer: IOffer,
  marketFee: number,
  protocolFee: number
) => {
  const interestRate = bestOffer.submitBidArgs.interestRate;
  const borrowerPrincipalBN = BigNumber.from(bestOffer.submitBidArgs.principal);
  const { totalPayLater } = calculateTotalPayLaterAndBorrowerPrincipal(
    borrowerPrincipalBN,
    +interestRate,
    marketFee,
    protocolFee
  );
  return {
    ...bestOffer,
    submitBidArgs: {
      ...bestOffer.submitBidArgs,
      totalPayLater: totalPayLater.toString(),
    },
  };
};
