import BottomNavigation from "components/bottomNavigation/";
import GoToCartButton from "components/goToCartButton";
import HeaderNavigation from "components/headerNavigation";
import { useAppDispatch } from "hooks";
import { useGetMyNfts } from "hooks/useGetMyNfts";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { calculateUserNavigationActions } from "slices/userNavigationSlice";
import { useAccount } from "wagmi";
import "./styles.scss";

export const App = () => {
  const { address } = useAccount();
  const dispatch = useAppDispatch();

  const { data: nftLoansData, refetch } = useGetMyNfts();

  useEffect(() => {
    void (async () => await refetch())();
    const hasNfts = nftLoansData ? nftLoansData.length > 0 : false;
    dispatch(calculateUserNavigationActions(address, hasNfts));
  }, [address, dispatch, nftLoansData, refetch]);

  return (
    <>
      <HeaderNavigation />
      <div className="app">
        <Outlet />
      </div>
    </>
  );
};

export const AppPage = () => {
  return (
    <div className="app-page">
      <GoToCartButton />
      <Outlet />
      <BottomNavigation />
    </div>
  );
};
