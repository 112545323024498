import cx from "classnames";
import { useEffect, useState } from "react";

import "./styles.scss";

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  endAdornment?: React.ReactNode;
  resetField?: boolean;
}

export const Input: React.FC<InputProps> = ({
  label,
  endAdornment,
  className,
  resetField = false,
  ...otherProps
}) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) =>
    setIsInvalid(!e.currentTarget.checkValidity());

  useEffect(() => {
    resetField && setIsInvalid(false);
  }, [resetField]);

  return (
    <div className={cx("input", className)}>
      {label && <div className="input-label">{label}</div>}
      <div className={cx("input-field", isInvalid && "invalid")}>
        <input {...otherProps} onBlur={handleBlur} />
        {endAdornment && <span className="end-adornment">{endAdornment}</span>}
      </div>
    </div>
  );
};
