import { ReactComponent as USDCHomesIcon } from "assets/icon.svg";
import { ReactComponent as RoofStock } from "assets/roofstock.svg";
import NavigationIcons from "components/navigationIcons";
import {
  DesktopComponent,
  MobileComponent,
} from "components/responsiveComponents";
import React from "react";
import "./styles.scss";

const today = new Date();
export const BottomNavigation: React.FC = () => {
  return (
    <>
      <div className="anpl-bottom-nav">
        <MobileComponent>
          <NavigationIcons />
        </MobileComponent>
        <DesktopComponent>
          <div className="desktop-footer">
            <div className="row">
              <a href="">Subscribe</a>
              <RoofStock />
              <USDCHomesIcon className="icon" />
            </div>
            <div className="secondary-text">
              Powered by Clarity © {today.getFullYear().toString()} USDC Homes
            </div>
          </div>
        </DesktopComponent>
      </div>
    </>
  );
};
