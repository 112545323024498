import { AddressType } from "constants/types/AddressType";

import {
  BasicOrderParams,
  DomainData,
  SubmitBidArgs,
} from "@clarity-credit/anpl-sdk/types/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MyNFT } from "hooks/useGetMyNfts";
import { Collection } from "types/Collection";
import { IAsset, IOffer, Trait } from "types/IAsset";
import { Loan } from "types/Loan";
import {
  addTotalPayLaterToBestOffer,
  calculateRecommendedOffer,
  convertOfferTerms,
} from "utils/offerUtils";

const baseUrl = "https://staging.api.apenowpaylater.com/v2";
const mockUrl = "https://ede73413-ace5-4122-9513-501a948d5979.mock.pstmn.io";

export interface PaginableResponse {
  pagination?: {
    next?: string;
    previous?: string;
    hasNextPage: boolean;
  };
}

export interface BaseResponse extends PaginableResponse {
  success: boolean;
  metadata?: any;
  error?: string;
  result?: any;
}

export interface PaginableRequest {
  cursor?: string;
}

export interface GetTrendingCollectionsResponse extends BaseResponse {
  result: Collection[];
}

export interface CollectionAssetsRequest extends PaginableRequest {
  slugOrAddress: string;
}

export interface CollectionAssetsResponse extends BaseResponse {
  result: IAsset[];
}

export interface CollectionDataResponse extends BaseResponse {
  result: Collection[];
}

export interface PostOffer {
  submitBidArgs: SubmitBidArgs;
  basicOrderParams: BasicOrderParams;
  domainData: DomainData;
  borrowerSignature: string;
}

export interface PostOfferParams {
  offers: PostOffer[];
}

export interface AssetsResponseData extends BaseResponse {
  result: IAsset[];
}

export interface AssetsResponse {
  data: AssetsResponseData;
}

export interface OffersResponseData extends BaseResponse {
  result: IOffer[];
}

export interface OfferAssetResponse {
  data: OffersResponseData;
}

export interface AssetListingReponseData {
  basicOrderParams: BasicOrderParams;
}

export interface OpenseaListingReponse extends BaseResponse {
  result: AssetListingReponseData[];
}

export interface AssetListingReponse {
  data: OffersResponseData;
}

export interface AssetsDetailsResponse {
  asset: IAsset;
}

export interface AssetTraitsData extends BaseResponse {
  result: Trait[];
}

export interface AssetsTraitsResponse {
  data: AssetTraitsData;
}

export interface GetSearchTokenByIdParams {
  slugOrAddress: string;
  tokenIds: string;
}

export interface GetAssetDetailsParams extends GetSearchTokenByIdParams {
  marketFee: number;
  protocolFee: number;
}

export interface GetLoansResponse {
  data: { result: Loan[] };
}

export interface GetLoansParams {
  address: string;
  addressType: AddressType;
}

export interface PendingRequestItem {
  submitBidArgs: SubmitBidArgs;
  asset: IAsset;
}

export interface PendingRequestsResponse {
  result?: PendingRequestItem[];
}

export interface GetSearchResultsReponse extends BaseResponse {
  result: Collection[];
}

export interface GetMyNftAssetDetailsParams {
  myNFTs: MyNFT[];
}

export interface MyNFTAsset extends MyNFT {
  alchemyImageUri: string;
  buyNowPriceRaw: string;
  displayName: string;
}

export interface GetMyNftAssetDetailsResponse {
  result: MyNFTAsset[];
}

export const anplApi = createApi({
  reducerPath: "anplApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getTrendingCollections: builder.query<GetTrendingCollectionsResponse, void>(
      {
        query: () => "trendingCollections",
      }
    ),
    getCollectionInfo: builder.query<CollectionDataResponse, string>({
      query: (slugOrAddress) => {
        const params = new URLSearchParams({
          slugOrAddress,
        });

        return {
          url: "collection",
          params,
        };
      },
    }),
    getCollectionAssets: builder.query<
      CollectionAssetsResponse,
      CollectionAssetsRequest
    >({
      query: ({ slugOrAddress, cursor }) => {
        const params = new URLSearchParams({
          slugOrAddress,
          ...(cursor && { cursor }),
        });

        return {
          url: "collection/assets",
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),
    getAssetDetails: builder.query<
      AssetsDetailsResponse,
      GetAssetDetailsParams
    >({
      async queryFn(
        { slugOrAddress, tokenIds, marketFee, protocolFee },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        const assetParams = new URLSearchParams({
          slugOrAddress,
          tokenId: tokenIds,
        });

        const assetDetailsResponse = (await fetchWithBQ({
          url: "/asset",
          params: assetParams,
        })) as AssetsResponse;

        const assetTraitsParams = new URLSearchParams({
          slugOrAddress,
          tokenId: tokenIds[0],
        });

        const assetTraitsResponse = (await fetchWithBQ({
          url: "/asset/traits",
          params: assetTraitsParams,
        })) as AssetsTraitsResponse;

        const asset = assetDetailsResponse.data.result[0];

        const offerParams = new URLSearchParams({
          contractAddress: asset.collection.contractAddress,
          tokenId: tokenIds,
        });

        const offerDetailsResponse = (await fetchWithBQ({
          url: "/offers",
          params: offerParams,
        })) as OfferAssetResponse;

        const listingParams = new URLSearchParams({
          slugOrAddress,
          tokenId: tokenIds,
        });

        const basicOrderParamsResponse = (await fetchWithBQ({
          url: "/asset/listings",
          params: listingParams,
        })) as AssetListingReponse;

        const bestOffer = asset.bestOffer
          ? addTotalPayLaterToBestOffer(asset.bestOffer, marketFee, protocolFee)
          : calculateRecommendedOffer(asset, marketFee, protocolFee);

        const basicOrderParams =
          basicOrderParamsResponse?.data?.success &&
          basicOrderParamsResponse.data.result.length > 0
            ? basicOrderParamsResponse.data.result[0].basicOrderParams!
            : undefined;

        return {
          data: {
            asset: {
              ...asset,
              bestOffer,
              offers: offerDetailsResponse.data.result,
              basicOrderParams,
              traits: assetTraitsResponse.data.result,
            },
          },
        };
      },
    }),
    postOffer: builder.mutation<BaseResponse, PostOfferParams>({
      query: (offer) => ({
        url: "offers",
        body: offer,
        method: "POST",
      }),
    }),
    getPendingRequests: builder.query<PendingRequestsResponse, string>({
      async queryFn(borrower, _queryApi, _extraOptions, fetchWithBQ) {
        const params = new URLSearchParams({
          borrower,
          withMetadata: "true",
        });

        const pendingRequestsResponse = (await fetchWithBQ({
          url: "/offers",
          params,
        })) as OfferAssetResponse;

        const { result } = pendingRequestsResponse?.data;
        const data = {} as PendingRequestsResponse;
        if (result)
          data.result = await Promise.all(
            result?.map(async (request) => {
              const params = new URLSearchParams({
                slugOrAddress: request.contractAddress!,
                tokenIds: request.tokenId!,
              });
              const assetData = (await fetchWithBQ({
                url: "/assets",
                params,
              })) as AssetsResponse;
              return {
                submitBidArgs: convertOfferTerms(request.submitBidArgs),
                asset: assetData?.data?.result?.[0],
              };
            })
          );

        return { data };
      },
      keepUnusedDataFor: 0,
    }),
    getLoans: builder.query<GetLoansResponse, GetLoansParams>({
      query: ({ addressType, address }) => {
        const params = new URLSearchParams({
          ...{ [addressType]: address },
          withMetadata: "true",
        });
        return {
          url: mockUrl + "/loans",
          params,
        };
      },
    }),
    getSearchResults: builder.query<CollectionDataResponse, string>({
      query: (search) => {
        const params = new URLSearchParams({
          search,
        });

        return {
          url: "collections",
          params,
        };
      },
    }),
    getOpenseaListing: builder.query<OpenseaListingReponse, IAsset>({
      query: (asset) => {
        const params = new URLSearchParams({
          slugOrAddress: asset.collection.openseaSlug,
          tokenId: asset.tokenId,
        });

        return {
          url: "/asset/listings",
          params,
        };
      },
    }),
    getSearchTokenByIdResult: builder.query<
      AssetsResponseData,
      GetSearchTokenByIdParams
    >({
      query: ({ slugOrAddress, tokenIds }) => {
        const params = new URLSearchParams({
          slugOrAddress,
          tokenIds,
        });

        return { url: "/assets", params };
      },
    }),
    getMyNftAssetDetails: builder.query<
      GetMyNftAssetDetailsResponse,
      GetMyNftAssetDetailsParams
    >({
      async queryFn({ myNFTs }, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await Promise.all(
          myNFTs.map(async (myNft) => {
            const params = new URLSearchParams({
              slugOrAddress: myNft.escrowedToken.contractAddress,
              tokenIds: myNft.escrowedToken.tokenId,
            });
            const assetDetailsResponse = (await fetchWithBQ({
              url: "/assets",
              params,
            })) as AssetsResponse;

            const assetDetails = assetDetailsResponse?.data?.result?.[0];
            return {
              ...myNft,
              alchemyImageUri: assetDetails?.alchemyImageUri,
              buyNowPriceRaw:
                assetDetails?.bestOffer?.submitBidArgs?.totalPurchasePrice,
              displayName: assetDetails?.assetName ?? assetDetails?.displayName,
            };
          })
        );
        return {
          data: {
            result,
          },
        };
      },
    }),
  }),
});

export const {
  useGetLoansQuery,
  useGetTrendingCollectionsQuery,
  useGetCollectionInfoQuery,
  useGetCollectionAssetsQuery,
  useLazyGetCollectionAssetsQuery,
  useGetAssetDetailsQuery,
  useGetPendingRequestsQuery,
  usePostOfferMutation,
  useLazyGetSearchResultsQuery,
  useLazyGetOpenseaListingQuery,
  useLazyGetSearchTokenByIdResultQuery,
  useGetMyNftAssetDetailsQuery,
} = anplApi;
