export const HOME_ROUTE = "";
export const SEARCH_ROUTE = "search";
export const COLLECTIONS_ROUTE = "collections";
export const COLLECTION_ROUTE = "collections/:slug";
export const CHECKOUT_ROUTE = "checkout";
export const SUBMITTED_ROUTE = "submitted";
export const PENDING_REQUESTS_ROUTE = "pending-requests";
export const MANAGE_LOANS_ROUTE = "manage-loans";
export const HOME_PAGE_SEARCHBAR_ROUTE = "searchbar";
export const MY_NFTS_ROUTE = "my-nfts";
export const SETTINGS_ROUTE = "settings";

export const ASSET_PATH = "asset";
export const ASSET_ROUTE = `${COLLECTION_ROUTE}/${ASSET_PATH}/:assetId`;

export const ROOFSTOCK_ROUTE = `${COLLECTIONS_ROUTE}/roofstock-onchain-homes`;
