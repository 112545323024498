import { formatAddressString } from "utils/addressUtils";

interface WalletLinkProps {
  address: string;
}

export const WalletLink: React.FC<WalletLinkProps> = ({ address }) => (
  <a
    href={`https://zapper.fi/account/${address}`}
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className="left-aligned" data-test="buyer">
      {formatAddressString(address)}
    </div>
  </a>
);
