import { SUBMITTED_ROUTE } from "constants/routes";

import { VARIANTS } from "components/button";
import ConnectedButton from "components/connectedButton";
import Section from "components/section";
import Table from "components/table";
import TokenPrice from "components/tokenPrice";
import { Token } from "components/tokenPrice/TokenPrice";
import WalletLink from "components/walletLink";
import { BigNumber } from "ethers";
import { useTransaction } from "hooks/useTransaction";
import { UserType, useVerifyTransaction } from "hooks/useVerifyTransaction";
import { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IOffer } from "types/IAsset";
import { RouterState } from "types/RouterState";
import { SIZES } from "types/sizes";
import { calculateDaysLeft, convertDurationToMonths } from "utils/dateUtils";
import { getAbsoluteRoute } from "utils/routerUtils";
import { Address, useAccount } from "wagmi";
import { AssetContext } from "../AssetPage";
import "./styles.scss";

export interface IOffers {
  buyer: string;
  payUpfront: string;
  payLater: string;
  duration: string;
  apr: number;
  expires: string;
}

interface OffersRowProps {
  offer: IOffer;
  buyNowPriceRaw: string;
}

export const OffersRow: React.FC<OffersRowProps> = ({
  offer,
  buyNowPriceRaw,
}) => {
  const { address } = useAccount();
  const navigate = useNavigate();

  const { tokenAddress, symbol } = useContext(AssetContext);

  const submitBidArgs = useMemo(
    () => ({
      ...offer.submitBidArgs,
      lender: address,
      referralAddress: address,
    }),
    [address, offer.submitBidArgs]
  );

  const { hasAllowance, verify } = useVerifyTransaction(
    tokenAddress as Address,
    BigNumber.from(submitBidArgs.principal),
    UserType.Lender
  );

  const { writeAsync, isError, isSuccess, isLoading } = useTransaction(
    "BNPLMarket",
    "executeUsingOffchainSignatures",
    [
      submitBidArgs,
      offer.basicOrderParams,
      offer.borrowerSignature,
      [], // lender signature not required
    ],
    { enabled: hasAllowance }
  );

  const fundLoan = useCallback(async () => {
    await verify();
    await writeAsync?.();
    if (isSuccess) {
      navigate(getAbsoluteRoute(SUBMITTED_ROUTE), {
        state: { isLenderFunded: true },
      } as RouterState);
    }
  }, [isSuccess, navigate, verify, writeAsync]);

  const {
    submitBidArgs: {
      downPayment,
      principal,
      duration,
      interestRate,
      signatureExpiration,
      totalPurchasePrice,
    },
  } = offer;

  return (
    <Table.Row className="offers-table-row">
      <div className="left-aligned" data-test="buyer">
        <WalletLink address={offer.submitBidArgs.borrower ?? ""} />
      </div>
      <TokenPrice
        className="right-aligned"
        price={downPayment}
        dataTest="pay-upfront"
        token={symbol}
      />
      <TokenPrice
        className="right-aligned"
        price={principal}
        dataTest="pay-later"
        token={symbol}
      />
      <div className="right-aligned">
        {(+downPayment / +totalPurchasePrice) * 100}%
      </div>
      <div className="right-aligned" data-test="duration">
        {convertDurationToMonths(duration)} months
      </div>
      <div className="right-aligned" data-test="apr">
        {+interestRate / 100} %
      </div>
      <div className="right-aligned" data-test="expires">
        {calculateDaysLeft(signatureExpiration)} days
      </div>
      {+buyNowPriceRaw > 0 && (
        <div className="fund-button">
          <ConnectedButton
            onClick={() => fundLoan()}
            variant={VARIANTS.SECONDARY}
            label={isLoading ? "Funding" : "Fund"}
            size={SIZES.COMPRESSED}
          />
          {isError && <div className="error-message">Error funding loan</div>}
        </div>
      )}
    </Table.Row>
  );
};

interface OffersTableProps {
  offers: IOffer[];
  buyNowPriceRaw: string;
}

export const OffersTable: React.FC<OffersTableProps> = ({
  offers,
  buyNowPriceRaw,
}) => (
  <Section
    sectionTitle={`Offers (${offers?.length ?? 0})`}
    className="offers-table-container"
  >
    <Table className="offers-table">
      <Table.HeaderRow>
        <div className="left-aligned">Borrower address</div>
        <div className="right-aligned">Down payment</div>
        <div className="right-aligned">Loan amount</div>
        <div className="right-aligned">Down payment %</div>
        <div className="right-aligned">Duration</div>
        <div className="right-aligned">APR</div>
        <div className="right-aligned">Expires</div>
        <div>Action</div>
      </Table.HeaderRow>
      {offers
        ?.filter((offer) => offer.submitBidArgs.borrower)
        ?.map((offer, index) => (
          <OffersRow
            offer={offer}
            key={index}
            buyNowPriceRaw={buyNowPriceRaw}
          />
        ))}
    </Table>
  </Section>
);
