import { ROOFSTOCK_ROUTE } from "constants/routes";

import { ReactComponent as BlueCheck } from "assets/blue_check.svg";
import EthPrice from "components/tokenPrice";
import Spinner from "components/spinner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetTrendingCollectionsQuery } from "slices/anplApiSlice";
import { Collection } from "types/Collection";
import "./styles.scss";

interface TrendingCollectionRowProps {
  trendingCollection: Collection;
  rowNumber: number;
}

const TrendingCollectionRow: React.FC<TrendingCollectionRowProps> = ({
  trendingCollection,
  rowNumber,
}) => {
  const navigate = useNavigate();
  const { name, openseaSlug, alchemyImageUri, loanVolume, offersCount } =
    trendingCollection;
  return (
    <div
      className="trending-collection"
      onClick={() => navigate(`/collections/${openseaSlug}`)}
    >
      <div>{rowNumber}</div>
      <img src={alchemyImageUri} />
      <div className="trending-collection-info">
        <div className="trending-collection-name">
          <span>{name}</span>
          <BlueCheck className="blue-check" />
        </div>
        <div className="collection-info-row amount">
          <EthPrice price={loanVolume} />
          <span className="secondary-text">loan volume</span>
          <span className="offers">{offersCount}</span>{" "}
          <span className="secondary-text"> offers</span>
        </div>
      </div>
    </div>
  );
};

export const TrendingCollections: React.FC = () => {
  const { data, isLoading } = useGetTrendingCollectionsQuery();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(ROOFSTOCK_ROUTE);
  }, [navigate]);

  return (
    <div className="trending-collections">
      <div className="header">Trending collections</div>
      <div className="trending-collections-container">
        <Spinner showSpinner={isLoading} />
        {data && (
          <>
            {data.result.map((collection, i) => (
              <TrendingCollectionRow
                trendingCollection={collection}
                rowNumber={i + 1}
                key={i}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
