import { ReactComponent as MediumIcon } from "assets/medium.svg";
import { ReactComponent as TwitterIcon } from "assets/twitter.svg";
import SearchCollection from "components/searchCollection";
import TrendingCollections from "components/trendingCollections";

import NFTBackground from "./nftBackground";

import "./styles.scss";

export const SearchAndExplore: React.FC = () => {
  return (
    <div className="search-and-explore">
      <NFTBackground />
      <div className="search-and-explore-container">
        <h1>Buy now, Pay later</h1>
        <p className="subheading">
          Secure prices, avoid risks and save upfront. <a href="">Learn more</a>
        </p>
        <SearchCollection />
        <TrendingCollections />
        <div className="search-and-explore-footer">
          <a href="">Subscribe</a>
          <a href="">Docs</a>
          <TwitterIcon className="clickable-icon" />
          <MediumIcon className="clickable-icon" />
        </div>
      </div>
    </div>
  );
};
