import { IBaseAsset } from "types/IAsset";

import "./styles.scss";

interface AssetBasicInfoProps {
  asset: IBaseAsset;
}

export const AssetBasicInfo: React.FC<AssetBasicInfoProps> = ({ asset }) => (
  <div className="asset-basic-info">
    <div className="collection">{asset?.collection?.name || " "}</div>
    <div className="token-id">#{asset?.tokenId || " "}</div>
  </div>
);
