import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "app/store";

import { anplApi } from "./anplApiSlice";

export enum NavigationActions {
  // searchBar = "searchBar",
  search = "search",
  pending = "pending",
  nfts = "nfts",
  manageLoans = "manageLoans",
  home = "home",
}

export interface UserNavigationState {
  actions: NavigationActions[];
}

const initialState: UserNavigationState = {
  actions: [NavigationActions.home],
};

export const userNavigationSlice = createSlice({
  name: "userNavigation",
  initialState,
  reducers: {
    addNavigationAction(state, action: PayloadAction<NavigationActions>) {
      state.actions.push(action.payload);
    },
    replaceNavigation(state, action: PayloadAction<NavigationActions[]>) {
      state.actions = action.payload;
    },
  },
});

export const { addNavigationAction, replaceNavigation } =
  userNavigationSlice.actions;
export default userNavigationSlice.reducer;

export const calculateUserNavigationActions =
  (address?: string, hasNfts?: boolean): AppThunk =>
  (dispatch) => {
    if (!address) {
      dispatch(replaceNavigation(initialState.actions));
    } else {
      const getAssetDetailsQuery = dispatch(
        anplApi.endpoints.getPendingRequests.initiate(address)
      );
      getAssetDetailsQuery
        .then((data) => {
          if (data?.data?.result) {
            if (data?.data?.result?.length > 0) {
              const actions = [
                NavigationActions.search,
                NavigationActions.pending,
              ];
              hasNfts && actions.push(NavigationActions.nfts);
              dispatch(replaceNavigation(actions));
            }
          } else {
            dispatch(replaceNavigation(initialState.actions));
          }
        })
        .catch((e) => console.error(e))
        .finally(() => getAssetDetailsQuery.unsubscribe());
    }
  };
