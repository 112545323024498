import { ReactComponent as AddToCart } from "assets/add_to_cart.svg";
import { ReactComponent as AddedToCart } from "assets/added.svg";
import { ReactComponent as Ape } from "assets/ape.svg";
import { ReactComponent as FilledCart } from "assets/cart_filled.svg";
import { ReactComponent as OpenSea } from "assets/os.svg";
import cx from "classnames";
import TokenPrice from "components/tokenPrice";
import { useIsAssetInCart } from "hooks/useIsAssetInCart";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyGetOpenseaListingQuery } from "slices/anplApiSlice";
import { addToCart, removeItem } from "slices/cartSlice";
import { IAsset, SubmitBidArgsWithTotalPayLater } from "types/IAsset";
import {
  addTotalPayLaterToBestOffer,
  calculateRecommendedOffer,
  convertOfferTerms,
  generateOfferString,
} from "utils/offerUtils";
import { calculatePaymentsFromOffer } from "utils/paymentUtils";
import { getAssetRoute } from "utils/routerUtils";
import "./styles.scss";
import { Token } from "components/tokenPrice/TokenPrice";

interface AssetTileProps {
  asset: IAsset;
}

interface PriceInfoProps {
  price: string;
  isApe?: boolean;
}

const PriceInfo: React.FC<PriceInfoProps> = ({ price, isApe = false }) => (
  <div className="price-info">
    <div className="source">
      {isApe ? (
        <>
          <Ape />
          <span>Upfront</span>
        </>
      ) : (
        <>
          <OpenSea />
          <span>Price</span>
        </>
      )}
    </div>
    <TokenPrice
      dataTest={isApe ? "asset-ape-price" : "asset-os-price"}
      price={price}
      token={Token.USDC}
    />
  </div>
);

export const AssetTile: React.FC<AssetTileProps> = ({ asset }) => {
  const [upatedAsset, setUpdatedAsset] = useState<IAsset>(asset);
  const [getOpenseaListing, { isLoading }] = useLazyGetOpenseaListingQuery();
  const {
    alchemyImageUri,
    displayName,
    offersCount,
    suggestedDownPayment,
    buyNowPriceRaw,
    tokenId,
    assetName,
    currencyType,
  } = upatedAsset;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isHome0 = asset.displayName === "Home onChain #0";
  // const [isAnimating, setIsAnimating] = useState(false);

  // const assetInCart = useIsAssetInCart({ ...upatedAsset });

  // const handleAddToCart = (e: React.MouseEvent<SVGElement>) => {
  //   e.stopPropagation();
  //   setIsAnimating(true);
  //   getOpenseaListing(asset)
  //     .then((response) => {
  //       const basicOrderParams = response.data?.result[0].basicOrderParams;
  //       setTimeout(() => setIsAnimating(false), 2000);

  //       const submitBidArgs = asset.bestOffer
  //         ? addTotalPayLaterToBestOffer(asset.bestOffer).submitBidArgs
  //         : calculateRecommendedOffer(asset).submitBidArgs;

  //       const offerTerms = convertOfferTerms(
  //         submitBidArgs as SubmitBidArgsWithTotalPayLater
  //       );
  //       const payments = calculatePaymentsFromOffer(offerTerms);
  //       setUpdatedAsset(asset);
  //       dispatch(
  //         addToCart({
  //           ...asset,
  //           payments,
  //           offerTerms,
  //           basicOrderParams: basicOrderParams!,
  //         })
  //       );
  //     })
  //     .catch((e) => {
  //       console.error("Error:", e);
  //       setIsAnimating(false);
  //     });
  // };

  // const handleRemoveFromCart = (e: React.MouseEvent<SVGElement>) => {
  //   if (!assetInCart) return;
  //   e.stopPropagation();
  //   dispatch(removeItem(assetInCart.id));
  // };

  // const isActive = assetInCart || isLoading;

  const assetImage = !!alchemyImageUri ? (
    <img
      src={alchemyImageUri}
      alt={displayName + " img"}
      data-test="asset-img"
    />
  ) : (
    <div className="default-image">
      <img
        src={asset.collection.alchemyImageUri}
        alt={displayName + " img"}
        data-test="asset-img"
        className="blurred-image"
      />
      <div className="collection-overlay">
        <img
          src={asset.collection.alchemyImageUri}
          alt={displayName + " img"}
          data-test="asset-img"
          className="round-image"
        />
        <p>Content not available yet</p>
      </div>
    </div>
  );
  return (
    <div
      className={cx(
        "asset-tile"
        // isActive && "in-cart"
      )}
      onClick={() =>
        // !isAnimating &&
        navigate(getAssetRoute(tokenId))
      }
    >
      {/* {isAnimating && (
        <div className="added-to-cart-overlay">
          <AddedToCart />
        </div>
      )} */}
      {assetImage}
      {/* <div className="add-to-cart-container clickable-icon">
        {isActive ? (
          <FilledCart
            width={13}
            height={11}
            onClick={handleRemoveFromCart}
            className="added-to-cart-icon"
          />
        ) : (
          <AddToCart onClick={handleAddToCart} className="add-to-cart-icon" />
        )}
      </div> */}
      <div className="asset-tile__info">
        <div className="row">
          <p data-test="asset-title">{assetName ?? displayName}</p>
          {offersCount > 0 && (
            <p className="offers">
              {offersCount} {generateOfferString(offersCount)}
            </p>
          )}
        </div>
        <>
          {+buyNowPriceRaw > 0 && (
            <TokenPrice
              price={buyNowPriceRaw}
              token={currencyType as Token}
              className="buy-now-price"
              dataTest="asset-ape-price"
            />
          )}
          <div className="secondary-text">2 year financing up to 65% LTV</div>
          {isHome0 && (
            <div className="secondary-text last-sale">
              Last sale 175,000 USDC
            </div>
          )}
        </>
      </div>
    </div>
  );
};
