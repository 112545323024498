import { SubmitBidArgs } from "@clarity-credit/anpl-sdk/types/types";
import { useGetFees } from "hooks/useGetFees";
import { useMemo } from "react";
import "./styles.scss";

interface PaymentBasicInfoProps {
  offerTerms: SubmitBidArgs;
}

export const PaymentBasicInfo: React.FC<PaymentBasicInfoProps> = ({
  offerTerms,
}) => {
  const { marketFee } = useGetFees();
  const downPaymentPercent = useMemo(
    () =>
      Math.floor(
        (+offerTerms.downPayment / +offerTerms.totalPurchasePrice) * 100
      ),
    [offerTerms]
  );

  const principalPercent = 100 - downPaymentPercent;
  const interestRate = offerTerms.interestRate;
  return (
    <div className="payment-basic-info">
      <div className="secondary-text" data-test="loan-info">
        {downPaymentPercent}% down, {interestRate}% APR
      </div>
      <div className="secondary-text">
        {(principalPercent * marketFee) / 100}% ApeNow fees
      </div>
    </div>
  );
};
