import { ReactComponent as BlueCheck } from "assets/blue_check.svg";
import TokenPrice from "components/tokenPrice";
import { DesktopComponent } from "components/responsiveComponents";
import { BigNumber } from "ethers";
import { Collection } from "types/Collection";
import roofstockBanner from "assets/roofstock_banner.png";

import "./styles.scss";
import { TokenTypes } from "constants/types/TokenTypes";
import { Token } from "components/tokenPrice/TokenPrice";

type CollectionHeaderProps = Collection;

export const CollectionHeader: React.FC<CollectionHeaderProps> = ({
  name,
  alchemyImageUri,
  openseaFloorPriceWei,
  loanVolume,
  offersCount,
}) => {
  const calculatedApeNowPrice = BigNumber.from(openseaFloorPriceWei || 0)
    .div(2)
    .toString();
  return (
    <div className="collection-header">
      <DesktopComponent>
        <img src={roofstockBanner} className="banner" />
      </DesktopComponent>
      <div className="collection-header-info">
        <img src={alchemyImageUri} />
        <div className="collection-details">
          <div className="collection-details-row">
            <h2 className="collection-name" data-test="name">
              {name}
            </h2>
            <BlueCheck />
          </div>
          <div className="collection-details-amounts">
            {/* HIDDEN FOR MVP
            <div className="collection-details-row amount">
              <EthPrice
                price={calculatedApeNowPrice}
                dataTest="upfront-floor"
              />
              <span className="secondary-text">floor upfront</span>
              <EthPrice price={openseaFloorPriceWei} dataTest="os-price" />
              <span className="secondary-text">opensea floor</span>
            </div> */}
            <div className="collection-details-row amount">
              <TokenPrice
                price={loanVolume}
                dataTest="total-loan-volume"
                token={Token.USDC}
              />
              <span className="secondary-text">total loan volume</span>
              <span className="offers" data-test="offers">
                {offersCount}
              </span>
              <span className="secondary-text">offers</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
