import { HOME_ROUTE } from "constants/routes";

import notFound from "assets/404.gif";
import Button from "components/button";
import HeaderNavigation from "components/headerNavigation";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="not-found">
      <HeaderNavigation />
      <div className="content">
        <img src={notFound} />
        <h2>You lost it!</h2>
        <Button label="Go back home" onClick={() => navigate(HOME_ROUTE)} />
      </div>
    </div>
  );
};
