import { ASSET_PATH, COLLECTIONS_ROUTE } from "constants/routes";

import { IAsset } from "types/IAsset";

export const getAssetRoute = (assetId: string) => ASSET_PATH + "/" + assetId;

export const getCollectionRouteFromAsset = (asset: IAsset) =>
  "/" + COLLECTIONS_ROUTE + "/" + asset.collection.openseaSlug;

export const getAbsoluteRoute = (route: string) => "/" + route;

export const generateCollectionRouteFromSlug = (slug: string) =>
  getAbsoluteRoute(COLLECTIONS_ROUTE + "/" + slug);

export const generateAssetRouteFromIAsset = (asset: IAsset) =>
  getCollectionRouteFromAsset(asset) + "/" + getAssetRoute(asset.tokenId);
