import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react-dom-interactions";
import { ReactComponent as TooltipIcon } from "assets/tooltip.svg";
import { useRef, useState } from "react";
import "./styles.scss";

export interface TooltipProps {
  content: string;
  placement?: Placement;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, placement }) => {
  const [open, setOpen] = useState(false);

  const arrowRef = useRef(null);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    placement: finalPlacement,
    middlewareData: { arrow: arrowData },
  } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(8),
      flip(),
      shift({ padding: 8 }),
      arrow({ element: arrowRef, padding: 3 }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { getFloatingProps } = useInteractions([
    useFocus(context),
    useHover(context),
    useRole(context, { role: "tooltip" }),
  ]);

  const staticSide =
    {
      top: "bottom",
      right: "left",
      bottom: "top",
      left: "right",
    }[finalPlacement.split("-")[0]] || "";

  return (
    <>
      <div className="icon-container" ref={reference}>
        <TooltipIcon />
      </div>
      {open && (
        <>
          <div
            className="tooltip"
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                top: y ?? "",
                left: x ?? "",
              },
            })}
          >
            <div
              className="arrow"
              ref={arrowRef}
              style={{
                left: arrowData?.x != null ? `${arrowData?.x}px` : "",
                top: arrowData?.y != null ? `${arrowData?.y}px` : "",
                [staticSide]: "-3px",
              }}
            ></div>
            {content}
          </div>
        </>
      )}
    </>
  );
};
