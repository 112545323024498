import { SETTINGS_ROUTE } from "constants/routes";

import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";

const SwitchNetwork: React.FC = () => {
  const { chain: activeChain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();

  if (process.env.NODE_ENV !== "development") return null;

  return (
    <>
      <div>Switch Network</div>
      {chains.map((chain) => (
        <div
          className={classNames({ active: chain.id === activeChain?.id })}
          key={chain.id}
          onClick={() => switchNetwork?.(chain.id)}
        >
          {chain.name}
        </div>
      ))}
    </>
  );
};

interface WalletPopoverProps {
  close: () => void;
}

export const WalletPopover: React.FC<WalletPopoverProps> = ({ close }) => {
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate(SETTINGS_ROUTE);
    close();
  };

  return (
    <div className="wallet-popup">
      <div onClick={() => disconnect()}>Disconnect wallet</div>
      {/* <div onClick={handleSettingsClick}>Settings</div> */}
    </div>
  );
};
