import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { BigNumber } from "ethers";
import { anplApi } from "slices/anplApiSlice";
import { IAsset, UserSubmitedTerms } from "types/IAsset";
import { generateIDFromAsset } from "utils/generateIDFromAsset";
import {
  calculateRecommendedOffer,
  calculateTotalPayLaterAndBorrowerPrincipal,
} from "utils/offerUtils";
import { calculatePaymentsFromOffer } from "utils/paymentUtils";

export interface IAssetWithID extends IAsset {
  id: string;
}

const cartAdapter = createEntityAdapter<IAssetWithID>();

const initialState = cartAdapter.getInitialState();

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action: PayloadAction<IAsset>) {
      const { payload } = action;
      cartAdapter.addOne(state, {
        ...payload,
        ...generateIDFromAsset(payload),
      });
    },
    addUserOfferToCart(state, action: PayloadAction<IAssetWithID>) {
      const { payload } = action;
      const offerTerms = payload?.offerTerms;
      const payments = calculatePaymentsFromOffer(offerTerms!);
      const offerTermsWithTotalPurchasePrice = {
        ...offerTerms!,
        totalPurchasePrice: payload.buyNowPriceRaw,
      };

      cartAdapter.addOne(state, {
        ...payload,
        payments,
        offerTerms: offerTermsWithTotalPurchasePrice,
      });
    },
    replaceCart(state, action: PayloadAction<IAssetWithID[]>) {
      cartAdapter.removeAll(state);
      cartAdapter.addMany(state, action.payload);
    },
    removeItem: cartAdapter.removeOne,
    updateItem: cartAdapter.updateOne,
    clearCart: cartAdapter.removeAll,
    upsertItem: cartAdapter.upsertOne,
  },
});

export const {
  addToCart,
  replaceCart,
  removeItem,
  updateItem,
  upsertItem,
  clearCart,
  addUserOfferToCart,
} = cartSlice.actions;
export default cartSlice.reducer;

export const { selectById: selectCartItemById, selectAll: selectAllCartItems } =
  cartAdapter.getSelectors((state: RootState) => state.cartReducer);

export const quickAddToCart =
  (asset: IAsset, marketFee: number, protocolFee: number): AppThunk =>
  (dispatch) => {
    const getAssetDetailsQuery = dispatch(
      anplApi.endpoints.getAssetDetails.initiate({
        slugOrAddress: asset.collection.openseaSlug,
        tokenIds: asset.tokenId,
        marketFee,
        protocolFee,
      })
    );
    getAssetDetailsQuery
      .then((result) => {
        if (result.data) {
          const asset = result.data.asset;
          const offerTerms =
            asset?.bestOffer ??
            calculateRecommendedOffer(asset, marketFee, protocolFee);
          const payments = calculatePaymentsFromOffer(offerTerms.submitBidArgs);
          dispatch(addToCart({ ...asset, payments }));
        }
      })
      .catch((error) => console.error(error));
    getAssetDetailsQuery.unsubscribe();
  };

export const updateCartItemOfferTerms =
  (
    offerTerms: UserSubmitedTerms,
    asset: IAssetWithID,
    marketFee: number,
    protocolFee: number
  ): AppThunk =>
  (dispatch) => {
    const principal = BigNumber.from(asset.buyNowPriceRaw).sub(
      offerTerms.downPayment
    );

    const { totalPayLater, borrowerPrincipal } =
      calculateTotalPayLaterAndBorrowerPrincipal(
        principal,
        +offerTerms.interestRate * 100,
        marketFee,
        protocolFee
      );

    const offerTermsWithLoanAmount: UserSubmitedTerms = {
      ...offerTerms,
      principal: borrowerPrincipal.toString(),
      totalPayLater: totalPayLater.toString(),
    };

    const newPayments = calculatePaymentsFromOffer(offerTermsWithLoanAmount);

    dispatch(
      updateItem({
        id: asset.id,
        changes: {
          offerTerms: {
            ...asset.offerTerms!,
            ...offerTermsWithLoanAmount,
          },
          payments: newPayments,
        },
      })
    );
  };
