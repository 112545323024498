import SearchCollection from "components/searchCollection";
import TrendingCollections from "components/trendingCollections";

import "./styles.scss";

export const SearchPage: React.FC = () => {
  return (
    <div className="search-page dynamic-height">
      <SearchCollection />
      <TrendingCollections />
    </div>
  );
};
