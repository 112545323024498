import TokenPrice from "components/tokenPrice";

import Table from "components/table";
import { UserSubmitedTerms } from "types/IAsset";

import { Token } from "components/tokenPrice/TokenPrice";
import { BigNumber } from "ethers";
import "./styles.scss";

interface PaymentsTableProps {
  offerTerms: UserSubmitedTerms;
  symbol?: Token;
}

export const PaymentsTable: React.FC<PaymentsTableProps> = ({
  offerTerms,
  symbol = Token.USDC,
}) => {
  const { principal, duration, interestRate, downPayment } = offerTerms;
  const paymentsToHide = +duration - 3;

  const principalBN = BigNumber.from(principal);
  const downPaymentBN = BigNumber.from(downPayment);
  const interestPayment = principalBN
    .mul(interestRate)
    .div(100)
    .mul(30)
    .div(365);

  const finalPayment = interestPayment.mul(duration).add(principal ?? 0);
  const totalPayment = downPaymentBN.add(principalBN);

  // if there's less than 5 payments (counting the last one) show them all
  // last payment is always added since it has the loan value added to it
  const length = +duration <= 4 ? +duration - 1 : 2;

  const paymentsToDisplay = Array.from({ length }).map((_, i) => {
    return {
      left: (
        <TokenPrice
          price={interestPayment.toString()}
          token={symbol}
          iconFirst
        />
      ),
      right: (
        <TokenPrice
          price={interestPayment.add(interestPayment.mul(i)).toString()}
          token={symbol}
          iconFirst
        />
      ),
    };
  });

  paymentsToHide > 0 &&
    paymentsToDisplay.push({
      left: <div className="secondary-text">{paymentsToHide} payments</div>,
      right: (
        <TokenPrice
          price={(paymentsToHide * +interestPayment).toString()}
          token={symbol}
          showPlusSign
          hideIcon
          className="secondary-text right-aligned"
        />
      ),
    });

  paymentsToDisplay.push({
    left: (
      <div className="total-display">
        <TokenPrice
          price={principal?.toString() ?? ""}
          token={symbol}
          iconFirst
        />
        (loan) +
        <TokenPrice
          price={interestPayment.toString()}
          token={symbol}
          hideIcon
        />
      </div>
    ),
    right: (
      <TokenPrice price={finalPayment.toString()} token={symbol} iconFirst />
    ),
  });

  return (
    <Table className="payments-table flex-2">
      <Table.HeaderRow>
        <div className="left-aligned">Payments per month ({duration})</div>
        <div className="hideable">Total paid to date</div>
      </Table.HeaderRow>
      {paymentsToDisplay?.map((payment, index) => (
        <Table.Row key={index} className="payments-table-row">
          <div className="left-aligned">{payment.left}</div>
          <div className="total-paid">{payment.right}</div>
        </Table.Row>
      ))}
      <Table.Row className="row-separator secondary-text">
        <div>Down Payment</div>
        <div className="right-aligned">
          <TokenPrice
            price={downPayment}
            token={symbol}
            hideIcon
            showPlusSign
          />
        </div>
      </Table.Row>
      <Table.Row className="secondary-text">
        <div>Total</div>
        <div className="total-paid">
          <TokenPrice
            price={totalPayment.toString()}
            token={symbol}
            iconFirst
          />
        </div>
      </Table.Row>
    </Table>
  );
};
