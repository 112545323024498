import { ReactComponent as BlueCheck } from "assets/blue_check.svg";
import { DesktopComponent } from "components/responsiveComponents";
import TokenPrice from "components/tokenPrice";
import { Token } from "components/tokenPrice/TokenPrice";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import { IAsset } from "types/IAsset";
import { getCollectionRouteFromAsset } from "utils/routerUtils";
import { AssetContext } from "../AssetPage";

import { AssetTraits } from "./AssetTraits";
import "./styles.scss";

interface AssetHeaderProps {
  asset: IAsset;
}

export const AssetHeader: FC<AssetHeaderProps> = ({ asset }) => {
  const { alchemyImageUri, displayName, buyNowPriceRaw, collection, traits } =
    asset;
  const { symbol } = useContext(AssetContext);
  return (
    <div className="asset-header">
      <img src={alchemyImageUri} alt="" />
      <div className="asset-info">
        <div className="asset-info-collection">
          <Link to={getCollectionRouteFromAsset(asset)}>{collection.name}</Link>
          <BlueCheck />
        </div>
        <div className="asset-info-id">
          <h2>{displayName}</h2>
        </div>
        <div className="asset-info-ape-price">
          <p className="secondary-text">Purchase price</p>
          {!!buyNowPriceRaw ? (
            <TokenPrice
              price={buyNowPriceRaw}
              dataTest="ape-price"
              token={symbol}
            />
          ) : (
            "-"
          )}
        </div>
        <div className="asset-info-traits">
          <div className="address">{asset.assetName}</div>
          {traits && (
            <DesktopComponent>
              <AssetTraits traits={traits} />
            </DesktopComponent>
          )}
        </div>
      </div>
    </div>
  );
};
