import { TermsView } from "constants/types/TermsViewTypes";

import Button from "components/button";
import { TokenInput } from "components/TokenInput/TokenInput";
import Input from "components/input";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { useAppDispatch } from "hooks";
import { updateCartItemOfferTerms } from "slices/cartSlice";
import { UserSubmitedTerms } from "types/IAsset";
import { SIZES } from "types/sizes";
import { useToken, Address } from "wagmi";

import { AssetInfo } from "../assetInfo/AssetInfo";
import { CartItemComponentProps } from "../CartItem";

import "./styles.scss";
import { useGetFees } from "hooks/useGetFees";

export const EditTerms: React.FC<CartItemComponentProps> = ({
  asset,
  setCartView,
}) => {
  const dispatch = useAppDispatch();
  const { marketFee, protocolFee } = useGetFees();
  const { data: tokenData } = useToken({
    address: asset.basicOrderParams.considerationToken as Address,
  });

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const submitedTerms = Object.fromEntries(formData) as UserSubmitedTerms;
    submitedTerms.downPayment = parseUnits(
      submitedTerms.downPayment,
      tokenData?.decimals
    ).toString();

    dispatch(
      updateCartItemOfferTerms(submitedTerms, asset, marketFee, protocolFee)
    );
    setCartView(TermsView.VIEW_TERMS);
  };

  return (
    <div className="edit-terms-cart">
      <div className="row">
        <img src={asset.alchemyImageUri} className="image-container" />
        <AssetInfo asset={asset} />
      </div>
      <div className="row">
        <form onSubmit={handleFormSubmit} className="edit-terms-form">
          <div className="row">
            <TokenInput
              type="number"
              name="downPayment"
              label="Down payment"
              className="full"
              defaultValue={asset.offerTerms?.downPayment}
              min={0}
              max={formatUnits(asset.buyNowPriceRaw, tokenData?.decimals)}
              required
              step={0.001}
            />
            <Input
              name="interestRate"
              endAdornment="%"
              label="APR"
              className="half"
              defaultValue={asset.offerTerms?.interestRate}
              type="number"
              min={0}
              required
            />
            <Input
              name="duration"
              endAdornment="months"
              label="Payment duration"
              defaultValue={asset.offerTerms?.duration}
              type="number"
              min={0}
              required
            />
          </div>
          <div className="row">
            <Input
              name="signatureExpiration"
              endAdornment="days"
              label="Offer expiration"
              className="full"
              defaultValue={asset.offerTerms?.signatureExpiration}
              type="number"
              min={0}
              required
            />
            <Button label="Save terms" type="submit" size={SIZES.COMPRESSED} />
          </div>
        </form>
      </div>
    </div>
  );
};
