import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { store } from "app/store";
import Spinner from "components/spinner";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { router } from "utils/createRouter";
import { chains, client } from "utils/createWagmiClient";
import { WagmiConfig } from "wagmi";
import "./polyfills";

const persistor = persistStore(store);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <WagmiConfig client={client}>
    <RainbowKitProvider
      chains={chains}
      modalSize="compact"
      appInfo={{ appName: "Ape Now Pay Later" }}
    >
      <Provider store={store}>
        <PersistGate loading={<Spinner />} persistor={persistor}>
          <React.StrictMode>
            <RouterProvider router={router} />
          </React.StrictMode>
        </PersistGate>
      </Provider>
    </RainbowKitProvider>
  </WagmiConfig>
);
