import { SubmitBidArgs } from "@clarity-credit/anpl-sdk/types/types";
import { BaseAssetTile } from "components/baseAssetTile/BaseAssetTile";
import Button, { VARIANTS } from "components/button";
import { DesktopAssetsView } from "components/desktopAssetsView/DesktopAssetsView";
import { EmailInfo } from "components/emailInfo/EmailInfo";
import { MobileComponent } from "components/responsiveComponents";
import Spinner from "components/spinner";
import { useGetUserWallet } from "hooks/useGetUserWallet";
import { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useGetPendingRequestsQuery } from "slices/anplApiSlice";
import { IAsset } from "types/IAsset";
import { SIZES } from "types/sizes";
import { generateAssetRouteFromIAsset } from "utils/routerUtils";

import { PendingRequestsItem } from "./PendingRequestsItem";
import "./styles.scss";

const title = "Pending offers";

const calculateInfoSentence = (submitBidArgs: SubmitBidArgs) => {
  const LTV =
    (+submitBidArgs.downPayment / +submitBidArgs.totalPurchasePrice) * 100;
  const interestRate = +submitBidArgs.interestRate / 100;

  return `${LTV}% LTV at ${interestRate}% APR for ${submitBidArgs.duration} months`;
};

export const PendingRequests: React.FC = () => {
  const { address } = useGetUserWallet();
  const { data, isLoading } = useGetPendingRequestsQuery(address!);
  const navigate = useNavigate();

  const navigateToAssetPage = (asset: IAsset) =>
    navigate(generateAssetRouteFromIAsset(asset));

  const loading = isLoading || !address;

  const handleCancelButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };
  return (
    <div className="pending-requests-page dynamic-height">
      <MobileComponent>
        <h2>Pending offers</h2>
        <Spinner showSpinner={loading} />
        {data?.result && address && (
          <div className="pending-requests-page-offers">
            {data.result.map((record, index) => (
              <PendingRequestsItem record={record} key={index} />
            ))}
          </div>
        )}
      </MobileComponent>
      <DesktopAssetsView
        title={title}
        subtitle={
          "If an offer is accepted, funds will be used to purchase the NFT home & will be kept in a vault"
        }
        isLoading={loading}
      >
        {data?.result &&
          address &&
          data.result.map((record, index) => (
            <BaseAssetTile
              /*               action={
                <Button
                  label="Cancel"
                  variant={VARIANTS.SECONDARY}
                  size={SIZES.COMPRESSED}
                  onClick={handleCancelButton}
                />
              } */
              summary={calculateInfoSentence(record.submitBidArgs)}
              buyNowPrice={record.submitBidArgs.totalPurchasePrice}
              key={index}
              image={record.asset.alchemyImageUri}
              displayName={record.asset.displayName}
              considerationToken={
                record.asset.bestOffer?.basicOrderParams?.considerationToken
              }
              navigate={() => navigateToAssetPage(record.asset)}
            />
          ))}
      </DesktopAssetsView>
      <EmailInfo />
    </div>
  );
};
