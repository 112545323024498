import { formatEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { usdFormatter } from "utils/currencyUtils";

interface DataResponse {
  data: { amount: number };
}

const catchError = (error: any) =>
  console.error("error fetching ETH-USD", error);

export const useConvertETHToUSD = (ethAmount: string, interval = 100000) => {
  const [exchangeRate, setExchangeRate] = useState(0);

  useEffect(() => {
    const fetchData = async () =>
      await fetch("https://api.coinbase.com/v2/prices/ETH-USD/spot").then(
        (response) => response.json() as Promise<DataResponse>
      );

    const id = setInterval(
      (function data() {
        fetchData()
          .then(({ data }) => setExchangeRate(data.amount))
          .catch(catchError);
        return data;
      })(),
      interval
    );

    return () => clearInterval(id);
  }, [interval]);

  const ethAmountInUSD = usdFormatter.format(
    +formatEther(ethAmount) * exchangeRate
  );

  return { ethAmountInUSD };
};
