import { TermsView } from "constants/types/TermsViewTypes";

import { SubmitBidArgs } from "@clarity-credit/anpl-sdk/types/types";
import { useMemo, useState } from "react";
import { IAsset } from "types/IAsset";

import { EditOfferTerms } from "./EditOfferTerms";
import "./styles.scss";
import { ViewOfferTerms } from "./ViewOfferTerms";
import { DesktopComponent } from "components/responsiveComponents";

export interface OfferTermsComponent {
  setOffersView: () => void;
  asset: IAsset;
}

interface OfferTermsProps {
  asset: IAsset;
  offerTerms: SubmitBidArgs;
  setLastOfferKey: React.Dispatch<React.SetStateAction<string>>;
  isAssetInCart: boolean;
}

export const OfferTerms: React.FC<OfferTermsProps> = ({
  asset,
  offerTerms,
  setLastOfferKey,
  isAssetInCart,
}) => {
  const [offersView, setOffersView] = useState<TermsView>(TermsView.VIEW_TERMS);

  const renderObject = useMemo(
    () => ({
      [TermsView.VIEW_TERMS]: (
        <ViewOfferTerms
          setOffersView={() => setOffersView(TermsView.EDIT_TERMS)}
          asset={asset}
          offerTerms={offerTerms}
          isAssetInCart={isAssetInCart}
        />
      ),
      [TermsView.EDIT_TERMS]: (
        <EditOfferTerms
          asset={asset}
          setOffersView={() => setOffersView(TermsView.VIEW_TERMS)}
          setLastOfferKey={setLastOfferKey}
        />
      ),
    }),
    [asset, setOffersView, setLastOfferKey, offerTerms, isAssetInCart]
  );

  return <div className="terms-container">{renderObject[offersView]}</div>;
};
