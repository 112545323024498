import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { anplApi } from "slices/anplApiSlice";
import cartSlice from "slices/cartSlice";
import userNavigationReducer from "slices/userNavigationSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const cartReducer = persistReducer(persistConfig, cartSlice);

export const store = configureStore({
  reducer: {
    userNavigationReducer,
    cartReducer,
    [anplApi.reducerPath]: anplApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(anplApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
