import { Address } from "@wagmi/core";
import { RootState } from "app/store";
import { MobileComponent } from "components/responsiveComponents";
import Spinner from "components/spinner";
import { Token } from "components/tokenPrice/TokenPrice";
import { ethers } from "ethers";
import { useGetFees } from "hooks/useGetFees";
import { useTokenAddress } from "hooks/useTokenAddress";
import { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetAssetDetailsQuery } from "slices/anplApiSlice";
import { selectCartItemById } from "slices/cartSlice";
import { IAsset } from "types/IAsset";
import { convertOfferTerms } from "utils/offerUtils";
import { useToken } from "wagmi";

import AssetHeader from "./header";
import { AssetTraits } from "./header/AssetTraits";
import { OffersTable } from "./offersTable/OffersTable";
import OfferTerms from "./offerTerms";
import "./styles.scss";

interface AssetDetailsProps {
  asset: IAsset;
}

type AssetContextType = {
  symbol: Token;
  decimals?: number;
  tokenAddress?: Address;
};

export const AssetContext = createContext<AssetContextType>({
  symbol: Token.USDC,
  decimals: undefined,
  tokenAddress: ethers.constants.AddressZero,
});

const AssetDetails: React.FC<AssetDetailsProps> = ({ asset }) => {
  const [lastOfferKey, setLastOfferKey] = useState("");
  const offerId = !!lastOfferKey ? lastOfferKey : asset?.bestOffer.id;

  const assetInCart = useSelector((state: RootState) =>
    selectCartItemById(state, offerId)
  );

  const offerTerms =
    assetInCart?.offerTerms ??
    convertOfferTerms(asset?.bestOffer?.submitBidArgs);

  return (
    <div className="asset-details">
      <AssetHeader asset={{ ...asset, offerTerms }} />
      <div className="asset-terms-and-offer">
        <OfferTerms
          asset={asset}
          offerTerms={offerTerms}
          setLastOfferKey={setLastOfferKey}
          isAssetInCart={!!assetInCart}
        />
        <OffersTable
          offers={asset.offers}
          buyNowPriceRaw={asset.buyNowPriceRaw}
        />
      </div>
      {asset.traits && (
        <MobileComponent>
          <AssetTraits traits={asset.traits} />
        </MobileComponent>
      )}
    </div>
  );
};

export const AssetPage: React.FC = () => {
  const { slug, assetId } = useParams();
  const { marketFee, protocolFee } = useGetFees();
  const { data, isLoading } = useGetAssetDetailsQuery({
    slugOrAddress: slug!,
    tokenIds: assetId!,
    marketFee,
    protocolFee,
  });

  const asset = data?.asset;
  const tokenAddress = useTokenAddress(
    asset?.basicOrderParams?.considerationToken as Address
  );
  const { data: tokenData } = useToken({ address: tokenAddress });

  const tokenContextData = {
    symbol: tokenData?.symbol as Token,
    decimals: tokenData?.decimals,
    tokenAddress,
  };

  return (
    <AssetContext.Provider value={tokenContextData}>
      <div className="asset-page dynamic-height">
        <Spinner showSpinner={isLoading} />
        {asset && <AssetDetails asset={asset} />}
      </div>
    </AssetContext.Provider>
  );
};
