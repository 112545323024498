import thankYou from "assets/thank_you.png";
import { PENDING_REQUESTS_ROUTE } from "constants/routes";
import { useIsMobile } from "hooks/useIsMobile";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouterState } from "types/RouterState";
import { getAbsoluteRoute } from "utils/routerUtils";
import { ReactComponent as SubmittedOffer } from "assets/submitted_offer.svg";

import "./styles.scss";

const NoEmailText: React.FC = () => (
  <>
    Stay tuned! If you would like to be notified when a lender approves your
    offer, <a href="">sign up for notifications</a>. If not, check back soon!
  </>
);

const EmailText: React.FC = () => (
  <>
    Stay tuned! When a lender approves your offer, you will be notified by
    email.
  </>
);

export const SubmittedPage: React.FC = () => {
  const email = " "; // TODO add logic to show the right message depending on email
  const { state } = useLocation() as RouterState;
  const isLender = state?.isLenderFunded;
  const successHeader = !!state ? (isLender ? "funded" : "submitted") : "";
  const navigate = useNavigate();
  useEffect(() => {
    if (isLender) return;
    setTimeout(() => navigate(getAbsoluteRoute(PENDING_REQUESTS_ROUTE)), 3000);
  });
  const isMobile = useIsMobile();
  return (
    <div className="submitted-page">
      {isMobile ? (
        <img src={thankYou} />
      ) : (
        <div className="success-background">
          <SubmittedOffer />
        </div>
      )}
      <div className="submitted-container">
        <h2 className="capitalize">Offer {successHeader} successfully</h2>
        <div className="stay-tuned">
          {isLender && (email ? <EmailText /> : <NoEmailText />)}
        </div>
      </div>
    </div>
  );
};;
