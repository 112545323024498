import { DateTime, Duration } from "luxon";

export const today = () => DateTime.now();
const oneMonthInSeconds = Duration.fromDurationLike({ days: 30 }).as("seconds");

export const calculateDaysLeft = (timestamp: string) => {
  const expirationDate = DateTime.fromSeconds(+timestamp);

  return Math.ceil(expirationDate.diff(today()).as("days"));
};

export const convertDaysLeftToDate = (days: string) =>
  today()
    .plus({ days: +days })
    .toUnixInteger();

export const convertDurationToMonths = (duration: string) =>
  +duration / oneMonthInSeconds;

export const convertMonthsToSeconds = (months: string) =>
  +months * oneMonthInSeconds;

export const calculateDaysSince = (timestamp: string) => {
  const expirationDate = DateTime.fromSeconds(+timestamp);

  return Math.ceil(today().diff(expirationDate).as("days"));
};
