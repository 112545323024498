import { TermsView } from "constants/types/TermsViewTypes";

import { ReactComponent as Chevron } from "assets/chevron.svg";
import cx from "classnames";
import Button, { VARIANTS } from "components/button";
import EthPrice from "components/tokenPrice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { removeItem } from "slices/cartSlice";

import { AssetInfo } from "../assetInfo/AssetInfo";
import { CartItemComponentProps } from "../CartItem";

import "./styles.scss";

export const ViewTerms: React.FC<CartItemComponentProps> = ({
  asset,
  setCartView,
}) => {
  const dispatch = useDispatch();
  const [isViewPayments, setIsViewPayments] = useState(false);

  return (
    <div className="cart-item">
      <div className="image-container">
        <img src={asset.alchemyImageUri} />
        <Button
          variant={VARIANTS.TEXT}
          label="Modify terms"
          onClick={() => setCartView(TermsView.EDIT_TERMS)}
        />
        <Button
          variant={VARIANTS.TEXT}
          label="Remove"
          onClick={() => dispatch(removeItem(asset.id))}
        />
      </div>
      <div className="asset-details">
        <AssetInfo asset={asset} />
        <Button
          variant={VARIANTS.TEXT}
          className="payments-table-button"
          label={
            <>
              <Chevron className={cx(isViewPayments && "opened")} />
              Payment breakdown
            </>
          }
          onClick={() => setIsViewPayments(!isViewPayments)}
        />
        {isViewPayments && (
          <div className="payment-rows">
            {asset.payments?.map((payment, index) => (
              <div key={index}>
                <span>Due {payment.date}</span>
                <EthPrice price={payment.paymentAmount} showPlusSign />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
