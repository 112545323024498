import "./styles.scss";

interface CheckboxProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "label"> {
  checked: boolean;
  setChecked: (value: boolean) => void;
  label: React.ReactNode;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  setChecked,
  label,
  ...props
}) => {
  return (
    <label className="checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={() => setChecked(!checked)}
        {...props}
      />
      <span data-test="checkbox-label"> {label} </span>
    </label>
  );
};
