import {
  MANAGE_LOANS_ROUTE,
  MY_NFTS_ROUTE,
  PENDING_REQUESTS_ROUTE,
  ROOFSTOCK_ROUTE,
  SEARCH_ROUTE,
} from "constants/routes";

import { RootState } from "app/store";
import { ReactComponent as USDCHomesIcon } from "assets/icon.svg";
import { ReactComponent as ManageLoans } from "assets/manage_loans.svg";
import { ReactComponent as NFTs } from "assets/nfts.svg";
import { ReactComponent as PendingRequests } from "assets/pending_requests.svg";
import { ReactComponent as Search } from "assets/search.svg";
import { useIsMobile } from "hooks/useIsMobile";
import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import "./styles.scss";

interface NavLinkItemProps {
  component: React.ReactNode;
  route: string;
}

const NavLinkItem: React.FC<NavLinkItemProps> = ({ component, route }) => (
  <NavLink
    to={route}
    className={({ isActive }) => (isActive ? "active" : "inactive")}
  >
    {component}
  </NavLink>
);

export const NavigationIcons: React.FC = () => {
  const { actions } = useSelector(
    (state: RootState) => state.userNavigationReducer
  );

  const isMobile = useIsMobile();

  const renderIcons = useMemo(
    () => ({
      pending: (
        <NavLinkItem
          component={<PendingRequests data-test="pending-icon" />}
          route={PENDING_REQUESTS_ROUTE}
        />
      ),
      nfts: (
        <NavLinkItem
          component={<NFTs data-test="nfts-icon" />}
          route={MY_NFTS_ROUTE}
        />
      ),
      manageLoans: (
        <NavLinkItem
          component={
            <ManageLoans
              data-test="manange-loans-icon"
              className="manage-loans"
            />
          }
          route={MANAGE_LOANS_ROUTE}
        />
      ),
      ...(isMobile && {
        search: (
          <NavLinkItem
            component={<Search data-test="search-icon" />}
            route={SEARCH_ROUTE}
          />
        ),
        home: (
          <NavLinkItem
            component={
              <USDCHomesIcon
                data-test="usdc-homes-icon"
                height="20px"
                width="auto"
              />
            }
            route={ROOFSTOCK_ROUTE}
          />
        ),
        // searchBar: <></>,
      }),
    }),
    [isMobile]
  );

  // by default actions always has 1 (default action)
  // this action is needed on mobile but not on desktop
  const shouldRenderIcons = isMobile ? actions.length > 0 : actions.length > 1;

  return (
    <>
      {shouldRenderIcons && (
        <div className="navigation-icons">
          {actions.map((action, key) => (
            <Fragment key={key}>{renderIcons[action]}</Fragment>
          ))}
        </div>
      )}
    </>
  );
};
