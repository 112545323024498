import { useConnectModal } from "@rainbow-me/rainbowkit";
import Button from "components/button";
import { ButtonProps } from "components/button/Button";
import { useAccount } from "wagmi";

export const ConnectedButton: React.FC<ButtonProps> = ({
  label,
  onClick,
  size,
  disabled,
  ...props
}) => {
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const commonProps = {
    size,
    "data-test": "connected-button",
  };
  return (
    <>
      {isConnected ? (
        <Button
          {...props}
          label={label}
          disabled={disabled}
          onClick={onClick}
          {...commonProps}
        />
      ) : (
        <Button
          {...props}
          label="Connect wallet"
          onClick={() => openConnectModal?.()}
          disabled={disabled}
          {...commonProps}
        />
      )}
    </>
  );
};
