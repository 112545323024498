import { LoanStatus } from "constants/types/LoanStatus";

import BaseAssetTile from "components/baseAssetTile";
import Button, { VARIANTS } from "components/button";
import DesktopAssetsView from "components/desktopAssetsView";
import { ReactComponent as LateIcon } from "assets/late.svg";
import { MobileComponent } from "components/responsiveComponents";
import Spinner from "components/spinner";
import { TileButton } from "components/tileButton/TileButton";
import { formatUnits } from "ethers/lib/utils.js";
import { useGetMyNfts } from "hooks/useGetMyNfts";
import { useGetUserWallet } from "hooks/useGetUserWallet";
import { useTransaction } from "hooks/useTransaction";
import { DateTime } from "luxon";
import React, { ReactNode, useMemo } from "react";
import { useGetMyNftAssetDetailsQuery } from "slices/anplApiSlice";
import { amountFormatter } from "utils/currencyUtils";
import { Address, useToken } from "wagmi";

import { MyNFTTile } from "./MyNFTTtile";
import "./styles.scss";

export const today = () => DateTime.now();

interface SummaryProps {
  amountDue: string;
  address: Address;
  nextDueDate: string;
  state: number;
}
const Summary: React.FC<SummaryProps> = ({
  amountDue,
  nextDueDate,
  address,
  state,
}) => {
  const { data } = useToken({ address });

  const amount = `${amountFormatter.format(
    +formatUnits(amountDue ?? 0, data?.decimals)
  )} ${data?.symbol}`;

  const renderSummary: {
    [key: number]: ReactNode;
  } = useMemo(
    () => ({
      [LoanStatus.active]: `Next loan payment of ${amount} is due on ${nextDueDate}.`,
      [LoanStatus.paid]: "Congrats on paying your loan!",
      [LoanStatus.late]: (
        <span className="row">
          <LateIcon /> You have a late payment of {amount}
        </span>
      ),
    }),
    [amount, nextDueDate]
  );

  return <>{renderSummary[state]}</>;
};

interface ActionButtonProps {
  bidId: string;
  state: number;
  address?: string;
  isMobile?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  bidId,
  state,
  address,
  isMobile = false,
}) => {
  const { writeAsync } = useTransaction(
    "BNPLMarket",
    "claimNFTFromRepaidLoan",
    [bidId],
    { enabled: state.toString() === LoanStatus.paid }
  );

  const mapLoanStatus: {
    [key: number]: {
      label: string;
      variant: VARIANTS;
      onClick?: () => any;
    };
  } = useMemo(
    () => ({
      [LoanStatus.active]: {
        label: "Make Payment",
        variant: VARIANTS.PRIMARY,
        onClick: () =>
          window.open(
            `https://app.teller.org/ethereum/profile/${address}/borrower`,
            "_blank"
          ),
      },
      [LoanStatus.paid]: {
        label: "Claim now",
        variant: VARIANTS.SECONDARY,
        onClick: async () => await writeAsync?.(),
      },
      [LoanStatus.late]: {
        label: "Pay Now",
        variant: VARIANTS.DANGER,
        onClick: () =>
          window.open(
            `https://app.teller.org/ethereum/profile/${address}/borrower`,
            "_blank"
          ),
      },
    }),
    [address, writeAsync]
  );

  return isMobile ? (
    <TileButton {...mapLoanStatus[state]} />
  ) : (
    <Button {...mapLoanStatus[state]} />
  );
};

export const MyNFTs: React.FC = () => {
  const { address } = useGetUserWallet();
  const { data: myNFTs, isLoading: contractLoading } = useGetMyNfts();
  const { data, isLoading: assetDetailsLoading } = useGetMyNftAssetDetailsQuery(
    { myNFTs: myNFTs! },
    { skip: !myNFTs }
  );
  const loans = data?.result;

  const showSpinner = !address || contractLoading || assetDetailsLoading;

  return (
    <div className="my-nfts-page dynamic-height">
      <MobileComponent>
        <h2>My NFTs</h2>
        <Spinner showSpinner={false} />
        <div className="my-nfts-page-assets">
          {loans?.map((loan, index) => (
            <MyNFTTile
              nft={{
                alchemyImageUri: loan.alchemyImageUri,
                buyNowPriceRaw: loan.buyNowPriceRaw,
                currencyType: loan.tokenAddress,
                displayName: loan.displayName,
              }}
              loanStatus={loan.state}
              isDefault={loan.isDefaulted}
              key={index}
              button={
                <ActionButton
                  bidId={loan.bidId}
                  state={loan.state}
                  address={address}
                  isMobile
                />
              }
            />
          ))}
        </div>
      </MobileComponent>
      <DesktopAssetsView
        title="My NFTs"
        subtitle="Congrats on your loans. Make your payments in a timely manner to keep your home!"
        isLoading={showSpinner}
      >
        {loans?.map((loan, index) => (
          <BaseAssetTile
            action={
              <ActionButton
                bidId={loan.bidId}
                state={loan.state}
                address={address}
              />
            }
            summary={
              <Summary
                address={loan.tokenAddress as Address}
                amountDue={loan.amountDue}
                nextDueDate={loan.nextPaymentDate}
                state={loan.state}
              />
            }
            buyNowPrice={loan.buyNowPriceRaw}
            key={index}
            image={loan.alchemyImageUri}
            displayName={loan.displayName}
            isDefault={loan.isDefaulted}
          />
        ))}
      </DesktopAssetsView>
    </div>
  );
};
