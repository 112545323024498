import cx from "classnames";
import { ButtonHTMLAttributes, ReactNode, useState } from "react";
import { ResizeableComponent } from "types/Component";
import { SIZES } from "types/sizes";

import "./styles.scss";

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ResizeableComponent {
  label: ReactNode;
  variant?: VARIANTS;
  animateOnClick?: boolean;
  animationText?: string;
}

export enum VARIANTS {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TEXT = "text",
  DANGER = "danger",
}

const Button: React.FC<ButtonProps> = ({
  label,
  variant = VARIANTS.PRIMARY,
  size = SIZES.STANDARD,
  onClick,
  className,
  animateOnClick = false,
  animationText,
  ...props
}) => {
  const [animating, setAnimating] = useState(false);

  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (animateOnClick) {
      setAnimating(true);
      setTimeout(() => setAnimating(false), 3000);
    }
    onClick?.(e);
  };

  return (
    <button
      {...props}
      className={cx(
        "button",
        variant,
        size,
        className,
        animating && "animating"
      )}
      onClick={handleOnClick}
    >
      <span className="button-label" data-test="button-label">
        {animating ? animationText : label}
      </span>
    </button>
  );
};

export default Button;
