import { AddressType } from "constants/types/AddressType";

import { useGetLoansQuery } from "slices/anplApiSlice";

import { useGetUserWallet } from "./useGetUserWallet";

export const useGetLoans = (addressType: AddressType) => {
  const { address } = useGetUserWallet();

  const getLoansParams = {
    addressType: addressType ?? " ",
    address: address ?? " ",
  };

  const { data, isLoading } = useGetLoansQuery(getLoansParams, {
    skip: !address,
  });

  const loans = data?.data.result || [];

  return { loans, showSpinner: isLoading || !address };
};
