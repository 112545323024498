import {
  RoofstockPropertyTypes,
  RoofstockTraits,
} from "constants/roofstockTraits";

import { ReactComponent as Apt } from "assets/apt.svg";
import { ReactComponent as Area } from "assets/area.svg";
import { ReactComponent as Bathrooms } from "assets/bathrooms.svg";
import { ReactComponent as Bedrooms } from "assets/bedrooms.svg";
import { ReactComponent as House } from "assets/house.svg";
import { ReactComponent as Location } from "assets/location.svg";
import { ReactComponent as Pin } from "assets/pin.svg";
import { ReactComponent as Wrench } from "assets/wrench.svg";
import React, { FC } from "react";
import { Trait } from "types/IAsset";

interface AssetTraitProps {
  traits: Trait[];
}

const renderTraitIcons: {
  [key: string]: React.ReactNode;
} = {
  [RoofstockTraits.Bedrooms]: <Bedrooms />,
  [RoofstockTraits.Bathrooms]: <Bathrooms />,
  [RoofstockTraits["Home Size Sq Ft"]]: <Area />,
  [RoofstockTraits["Lot Size Sq Ft"]]: <Area />,
  [RoofstockTraits.Market]: <Location />,
  [RoofstockTraits.City]: <Pin />,
  [RoofstockTraits.State]: <Pin />,
  [RoofstockTraits["Year Built"]]: <Wrench />,
};

const renderPropertyTypeIcons: { [key: string]: React.ReactNode } = {
  [RoofstockPropertyTypes.House]: <House />,
  [RoofstockPropertyTypes.Condo]: <House />,
  [RoofstockPropertyTypes.Apartment]: <Apt />,
  [RoofstockPropertyTypes.Commercial]: <Apt />,
};

const renderTrait = (trait: Trait) => {
  const { trait_type } = trait;
  let value;
  switch (trait_type) {
    case RoofstockTraits["Property Type"]:
      value = (
        <>
          {renderPropertyTypeIcons[trait.value]} {trait.value}
        </>
      );
      break;
    case RoofstockTraits["Home Size Sq Ft"]:
      value = trait.value + ` sqft home`;
      break;
    case RoofstockTraits["Lot Size Sq Ft"]:
      value = trait.value + ` sqft lot`;
      break;
    case RoofstockTraits["Year Built"]:
      value = "Built in " + trait.value;
      break;
    case RoofstockTraits.Market:
      value = trait.value;
      break;
    default:
      value = trait.value + " " + trait.trait_type;
  }

  return (
    <>
      {renderTraitIcons[trait.trait_type]} {value}
    </>
  );
};

export const AssetTraits: FC<AssetTraitProps> = ({ traits }) => {
  return (
    <div className="traits-container">
      {traits?.map((trait, index) => (
        <div key={index} className="trait">
          {renderTrait(trait)}
        </div>
      ))}
    </div>
  );
};
