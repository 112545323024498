import { CHECKOUT_ROUTE } from "constants/routes";

import { RootState } from "app/store";
import { ReactComponent as EmptyCart } from "assets/cart_empty.svg";
import { ReactComponent as FilledCart } from "assets/cart_filled.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAllCartItems } from "slices/cartSlice";

import "./styles.scss";

export const CartButton: React.FC = () => {
  const cartItems = useSelector((state: RootState) =>
    selectAllCartItems(state)
  );
  const navigate = useNavigate();

  return (
    <>
      {cartItems.length > 0 ? (
        <div
          className="filled-cart clickable-icon"
          onClick={() => navigate(CHECKOUT_ROUTE)}
        >
          <FilledCart height="1em" width="1em" data-test="filled-cart" />
          <span className="cart-count">{cartItems.length}</span>
        </div>
      ) : (
        <EmptyCart height="1em" width="1em" data-test="empty-cart" />
      )}
    </>
  );
};
