import { signOffchainOffer } from "@clarity-credit/anpl-sdk";
import cx from "classnames";
import Button, { VARIANTS } from "components/button";
import { ButtonProps } from "components/button/Button";
import ConnectedButton from "components/connectedButton";
import PaymentsTable from "components/paymentsTable";
import {
  DesktopComponent,
  MobileComponent,
} from "components/responsiveComponents";
import Section from "components/section";
import Spinner from "components/spinner";
import TokenPrice from "components/tokenPrice";
import Tooltip from "components/tooltip";
import { SUBMITTED_ROUTE } from "constants/routes";
import { BigNumber } from "ethers";
import { useGetDomainData } from "hooks/useGetDomainData";
import { UserType, useVerifyTransaction } from "hooks/useVerifyTransaction";
import { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePostOfferMutation } from "slices/anplApiSlice";
import { addToCart } from "slices/cartSlice";
import { IAsset, SubmitBidArgsWithTotalPayLater } from "types/IAsset";
import { SIZES } from "types/sizes";
import { prepareOfferTerms } from "utils/offerUtils";
import { calculatePaymentsFromOffer } from "utils/paymentUtils";
import { getAbsoluteRoute } from "utils/routerUtils";
import { useAccount, useSigner } from "wagmi";

import InfoSection from "../../../components/infoSection";

import { useGetFees } from "hooks/useGetFees";
import { AssetContext } from "../AssetPage";
import { DesktopOfferTermsButton } from "./DesktopOfferTermsButton.tsx";
import { OfferTermsComponent } from "./OfferTerms";

interface ViewOfferTermsProps extends OfferTermsComponent {
  isAssetInCart: boolean;
  offerTerms: SubmitBidArgsWithTotalPayLater;
}

const createButtonProps = (
  label: string,
  onClick: () => void | void,
  animateOnClick?: boolean
) => ({
  label,
  onClick,
  ...(animateOnClick && { animateOnClick, animationText: "Added!" }),
});

export const ViewOfferTerms: React.FC<ViewOfferTermsProps> = ({
  setOffersView,
  offerTerms,
  asset,
  isAssetInCart,
}) => {
  const { address } = useAccount();
  const { marketFee } = useGetFees();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { symbol, tokenAddress } = useContext(AssetContext);

  const { verify } = useVerifyTransaction(
    tokenAddress!,
    BigNumber.from(offerTerms.downPayment).toString(),
    UserType.Borrower
  );

  const domainData = useGetDomainData();

  const [postOffer] = usePostOfferMutation();
  const { data: signer } = useSigner();

  const { isConnected } = useAccount();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const offers = asset.offersCount;

  const downPayment = offerTerms.downPayment ?? asset.suggestedDownPayment;

  const payments = !!asset.payments?.length
    ? asset.payments
    : calculatePaymentsFromOffer(offerTerms);

  const { buyNowPriceRaw } = asset;
  const hasBuyNowPrice = !!buyNowPriceRaw;

  const handleMakeOffers = useCallback(
    (assets: IAsset[]) => {
      if (!signer || !asset) return;

      setIsLoading(true);
      Promise.all(
        assets.map(async (item) => {
          await verify();
          const submitBidArgs = prepareOfferTerms(offerTerms, address!, true);
          let borrowerSignature = "";
          borrowerSignature = await signOffchainOffer({
            basicOrderParams: item.basicOrderParams!,
            domainData,
            submitBidArgs,
            signer,
          });
          return {
            basicOrderParams: item.basicOrderParams!,
            domainData,
            submitBidArgs,
            borrowerSignature,
          };
        })
      )
        .then(
          (offers) => {
            postOffer({ offers })
              .then((result) => {
                if ("error" in result) throw result.error;
                if ("error" in result.data) throw result.data.error;
                navigate(getAbsoluteRoute(SUBMITTED_ROUTE), {
                  state: { isBorrower: true },
                });
              })
              .catch((err) => {
                setError("Unable to submit offers");
                console.error("Error posting offer\n", err);
              });
          },
          (err) => {
            setError("Failed to sign offers");
            console.error("Error signing offer\n", err);
          }
        )
        .finally(() => setIsLoading(false));
    },
    [
      address,
      asset,
      domainData,
      navigate,
      offerTerms,
      postOffer,
      signer,
      verify,
    ]
  );

  const getButtonProps = useCallback(() => {
    let buttonProps: ButtonProps = { label: "" };
    let sectionTitleEndProps: ButtonProps = { label: "" };
    const addToCartOptions = createButtonProps("Add to cart", () => {
      dispatch(
        addToCart({
          ...asset,
          payments,
          offerTerms,
        })
      );
    });

    const submitOptions = createButtonProps("Submit", () =>
      handleMakeOffers([asset])
    );

    const addToCartAnimatedOptions = createButtonProps(
      addToCartOptions.label,
      addToCartOptions.onClick,
      true
    );

    const modifyTermsOptions = createButtonProps("Modify terms", () =>
      setOffersView()
    );

    // if (isAssetInCart) {
    //   buttonProps = createButtonProps(
    //     "Back to collection",
    //     () => navigate(getCollectionRouteFromAsset(asset)),
    //     true
    //   );
    //   sectionTitleEndProps = createButtonProps("Add another offer", () =>
    //     setOffersView()
    //   );
    // } else {
    if (isConnected) {
      if (offers > 0) {
        buttonProps = modifyTermsOptions;
        sectionTitleEndProps = submitOptions;
      } else {
        buttonProps = submitOptions;
        sectionTitleEndProps = modifyTermsOptions;
      }
    }
    // }

    return { buttonProps, sectionTitleEndProps };
  }, [
    handleMakeOffers,
    asset,
    isConnected,
    dispatch,
    payments,
    offerTerms,
    setOffersView,
    offers,
  ]);

  const { interestRate, principal, totalPurchasePrice } = offerTerms;

  const DownPayment = (
    <InfoSection
      label="Pay now"
      content={
        <div className="pay-now">
          <TokenPrice price={downPayment} dataTest="pay-now" token={symbol} />
          <span className="secondary-text">
            ({(+downPayment / +totalPurchasePrice) * 100 || 0}% down)
          </span>
        </div>
      }
    />
  );
  const LoanAmount = (
    <InfoSection
      label={
        <>
          Pay later
          <Tooltip
            content={`Includes ${
              marketFee / 100
            }% USDC Homes fees & lender APR`}
            placement="top"
          />
        </>
      }
      content={
        principal && (
          <TokenPrice price={principal} dataTest="pay-later" token={symbol} />
        )
      }
    />
  );
  const APR = (
    <InfoSection
      label="Lender APR"
      content={interestRate && interestRate + "%"}
      dataTest="lender-apr"
    />
  );
  const OfferExpiration = (
    <InfoSection
      label="Offer expires"
      content={
        offerTerms.signatureExpiration && (
          <>{offerTerms.signatureExpiration} days</>
        )
      }
      dataTest="expiration"
    />
  );
  return (
    <>
      <Section
        sectionTitle="Offer Terms"
        sectionTitleEnd={
          <Button
            variant={VARIANTS.TEXT}
            className="section-title-button"
            {...getButtonProps().sectionTitleEndProps}
          />
        }
        className={cx("offer-terms", !hasBuyNowPrice && "blurred")}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="offer-terms-container">
              <MobileComponent>
                <div className="data-row">
                  {DownPayment}
                  <ConnectedButton
                    {...getButtonProps().buttonProps}
                    size={SIZES.COMPRESSED}
                    variant={
                      isAssetInCart ? VARIANTS.SECONDARY : VARIANTS.PRIMARY
                    }
                    data-test="offer-button"
                  />
                </div>
                <div className="data-row">
                  {LoanAmount}
                  {APR}
                  {OfferExpiration}
                </div>
              </MobileComponent>
              <DesktopComponent>
                <div className="price-info">
                  {DownPayment}
                  <InfoSection
                    label="Purchase Price"
                    className="purchase-price"
                    content={
                      <TokenPrice
                        price={totalPurchasePrice}
                        dataTest="pay-now"
                        token={symbol}
                      />
                    }
                  />
                </div>
                <div className="terms-info">
                  {LoanAmount}
                  {OfferExpiration}
                  <InfoSection label="Payment cycle" content="Monthly" />
                  {APR}
                </div>
              </DesktopComponent>
              <PaymentsTable offerTerms={offerTerms} symbol={symbol} />
            </div>
            <DesktopOfferTermsButton
              {...getButtonProps().buttonProps}
              disabled={!hasBuyNowPrice}
            />
            {error && <div className="error-message">{error} </div>}
          </>
        )}
      </Section>
      {!hasBuyNowPrice && (
        <h2 className="no-offers-message">Not listed for sale</h2>
      )}
    </>
  );
};
