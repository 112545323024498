import { Address } from "@wagmi/core";
import { USDC_HOMES } from "constants/marketIds";
import { BigNumberish, ethers } from "ethers";
import { useCallback, useMemo } from "react";
import { useAccount, useContractRead } from "wagmi";

import { useContracts } from "./useContracts";
import { useTokenAddress } from "./useTokenAddress";
import { useTokenAllowance } from "./useTokenAllowance";
import { useTransaction } from "./useTransaction";

export enum UserType {
  Lender,
  Borrower,
}

export const useVerifyTransaction = (
  tokenAddress: Address,
  amount: BigNumberish,
  userType: UserType
) => {
  const contracts = useContracts();
  const { address } = useAccount();

  const hasApprovedMarketForwarder = useContractRead({
    ...contracts.TellerV2,
    functionName: "hasApprovedMarketForwarder",
    args: [
      +USDC_HOMES, // marketID
      contracts.BNPLMarket?.address,
      address,
    ],
    watch: true,
  });

  const approveMarketForwarder = useTransaction(
    "TellerV2",
    "approveMarketForwarder",
    [
      +USDC_HOMES, // market ID
      contracts.BNPLMarket?.address,
    ],
    {
      enabled: !hasApprovedMarketForwarder.data,
    }
  );

  const lendingTokenAddress = useTokenAddress(tokenAddress);

  const allowanceDestination =
    userType === UserType.Lender
      ? contracts.TellerV2?.address
      : contracts.BNPLMarket?.address;

  const lendingTokenAllowance = useTokenAllowance(
    tokenAddress,
    allowanceDestination
  );

  const approveLendingToken = useTransaction(
    lendingTokenAddress!,
    "approve",
    [allowanceDestination, ethers.constants.MaxUint256],
    {
      enabled: !!lendingTokenAllowance.data?.lt(amount),
    }
  );

  const hasAllowance = !!lendingTokenAllowance.data?.gt(amount);

  const verify = useCallback(async () => {
    if (!hasApprovedMarketForwarder.data) {
      await approveMarketForwarder.writeAsync?.();
    }

    if (!hasAllowance) {
      await approveLendingToken.writeAsync?.();
    }
  }, [
    hasApprovedMarketForwarder.data,
    hasAllowance,
    approveMarketForwarder,
    approveLendingToken,
  ]);

  return useMemo(() => {
    return {
      verify,
      hasAllowance,
      isLoading:
        approveLendingToken.isLoading || approveMarketForwarder.isLoading,
      isSuccess:
        approveLendingToken.isSuccess || approveMarketForwarder.isSuccess,
      isError: approveLendingToken.isError || approveMarketForwarder.isError,
    };
  }, [
    approveLendingToken.isError,
    approveLendingToken.isLoading,
    approveLendingToken.isSuccess,
    approveMarketForwarder.isError,
    approveMarketForwarder.isLoading,
    approveMarketForwarder.isSuccess,
    hasAllowance,
    verify,
  ]);
};
