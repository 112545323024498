import { LoanStatus } from "constants/types/LoanStatus";

import defaulted from "assets/defaulted.gif";
import { VARIANTS } from "components/button";
import TileButton from "components/tileButton";
import { ReactNode, useMemo } from "react";
import { useAccount } from "wagmi";

interface MyNFTTileProps {
  nft: {
    alchemyImageUri: string;
    displayName: string;
    buyNowPriceRaw: string;
    currencyType: string;
  };
  loanStatus: number;
  button: ReactNode;
  isDefault: boolean;
}

export const MyNFTTile: React.FC<MyNFTTileProps> = ({
  nft,
  loanStatus,
  isDefault,
}) => {
  const { alchemyImageUri } = nft;
  const { address } = useAccount();

  const mapLoanStatus = useMemo(
    () => ({
      [LoanStatus.active]: {
        label: "Repay",
        variant: VARIANTS.SECONDARY,
        onClick: () =>
          window.open(
            `https://app.teller.org/ethereum/profile/${address}/borrower`,
            "_blank"
          ),
      },
      [LoanStatus.paid]: {
        label: "Claim now",
        variant: VARIANTS.PRIMARY,
      },
      [LoanStatus.default]: {
        label: "",
        variant: VARIANTS.TEXT,
      },
    }),
    [address]
  );

  return (
    <div className="my-nfts-tile asset-tile">
      <img src={nft.alchemyImageUri} alt="" />
      <div className="asset-basic-info">
        <div className="collection">{nft.displayName}</div>
      </div>
      {isDefault && (
        <div className="defaulted">
          <img src={defaulted} />
        </div>
      )}
      {<TileButton {...mapLoanStatus[loanStatus]} />}
    </div>
  );
};
