import AssetBasicInfo from "components/assetBasicInfo";
import EthPrice from "components/tokenPrice";
import PaymentBasicInfo from "components/paymentBasicInfo";
import { useConvertETHToUSD } from "hooks/useConvertETHToUSD";
import { IAssetWithID } from "slices/cartSlice";
import { createPaymentDurationString } from "utils/paymentUtils";

import "./styles.scss";

interface AssetInfoProps {
  asset: IAssetWithID;
}

export const AssetInfo: React.FC<AssetInfoProps> = ({ asset }) => {
  const terms = asset.offerTerms ?? asset.offers[0].submitBidArgs;

  const downPayment = terms.downPayment ?? "0";

  const { ethAmountInUSD } = useConvertETHToUSD(downPayment);

  return (
    <div className="asset-info">
      <div className="row">
        <AssetBasicInfo asset={asset} />
        <EthPrice price={downPayment} />
      </div>
      <div className="row">
        <PaymentBasicInfo offerTerms={terms} />
        <div>
          <div className="secondary-text usd-amount">{ethAmountInUSD}</div>
          <div className="secondary-text">
            {createPaymentDurationString(
              (terms.totalPayLater ?? 0).toString(),
              terms.duration
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
