import {
  DesktopComponent,
  MobileComponent,
} from "components/responsiveComponents";
import ConnectedButton from "components/connectedButton";
import Checkbox from "components/checkbox";
import { useState } from "react";
import { ButtonProps } from "components/button/Button";

interface DesktopOfferTermsButtonProps extends ButtonProps {
  disabled?: boolean;
}

export const DesktopOfferTermsButton: React.FC<
  DesktopOfferTermsButtonProps
> = ({ disabled = false, ...props }) => {
  const [isAcceptTnC, setIsAcceptTnC] = useState(false);

  const disableButton = disabled || !isAcceptTnC;

  return (
    <DesktopComponent>
      <div className="desktop-action-button">
        {!disabled && (
          <Checkbox
            checked={isAcceptTnC}
            setChecked={setIsAcceptTnC}
            label="I confirm to the promissory note sent by the lender"
            name="accept-tnc"
          />
        )}
        <ConnectedButton {...props} type="submit" disabled={disableButton} />
      </div>
    </DesktopComponent>
  );
};
