import Button, { VARIANTS } from "components/button";
import Input from "components/input";
import { SetStateAction, useState } from "react";
import { SIZES } from "types/sizes";

import "./styles.scss";

interface EmailInfoProps {
  showMoreInfo?: boolean;
  show?: boolean; // temp
}

interface EditEmailProps {
  email: string;
  setEmail: React.Dispatch<SetStateAction<string>>;
  setIsEditEmail: React.Dispatch<SetStateAction<boolean>>;
  isEditEmail: boolean;
}

const EditEmail: React.FC<EditEmailProps> = ({
  email,
  setEmail,
  setIsEditEmail,
  isEditEmail,
}) => {
  return isEditEmail ? (
    <form>
      <Input onChange={(e) => setEmail(e.currentTarget.value)} />
      <Button
        label="Update email"
        variant={VARIANTS.PRIMARY}
        size={SIZES.COMPRESSED}
        onClick={() => setIsEditEmail(false)}
      />
    </form>
  ) : (
    <>
      <span className="email">{email}</span>
      <Button
        label="edit"
        variant={VARIANTS.TEXT}
        onClick={() => setIsEditEmail(true)}
        className="edit-email"
      />
    </>
  );
};

export const EmailInfo: React.FC<EmailInfoProps> = ({
  showMoreInfo,
  show = false,
}) => {
  const [email, setEmail] = useState("test@test.com");
  const [isEditEmail, setIsEditEmail] = useState(false);

  return (
    <>
      {show && (
        <div className="email-info">
          <span className="email-label">
            Email notifications{isEditEmail ? " (optional)" : ":"}{" "}
          </span>
          <EditEmail
            email={email}
            setEmail={setEmail}
            setIsEditEmail={setIsEditEmail}
            isEditEmail={isEditEmail}
          />
          {showMoreInfo && (
            <p>
              If an offer is accepted, funds will go to the lender and a loan
              agreement is made.
            </p>
          )}
        </div>
      )}
    </>
  );
};
