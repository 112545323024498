import { ComponentWithClassName } from "types/Component";
import "./styles.scss";

interface InfoSectionProps extends ComponentWithClassName {
  label: React.ReactNode;
  content: React.ReactNode;
  dataTest?: string;
}

export const InfoSection: React.FC<InfoSectionProps> = ({
  label,
  content,
  dataTest,
}) => (
  <div className="info-section">
    <div className="info-section-label">{label}</div>
    <div className="info-section-content" data-test={dataTest}>
      {content}
    </div>
  </div>
);
