import cx from "classnames";
import { ComponentWithChildren, ComponentWithClassName } from "types/Component";
import "./styles.scss";

interface SectionProps extends ComponentWithChildren, ComponentWithClassName {
  sectionTitle: string;
  sectionTitleEnd?: React.ReactNode;
}

export const Section: React.FC<SectionProps> = ({
  sectionTitle,
  sectionTitleEnd,
  children,
  className,
}) => (
  <div className={cx("section", className)}>
    <div className="section-header">
      <span className="section-header-title">{sectionTitle}</span>
      {sectionTitleEnd && sectionTitleEnd}
    </div>
    <div className="section-body">{children}</div>
  </div>
);
