import defaulted from "assets/defaulted.gif";
import TokenPrice from "components/tokenPrice";
import { Token } from "components/tokenPrice/TokenPrice";
import { useTokenAddress } from "hooks/useTokenAddress";
import { FC, ReactNode } from "react";
import { Address, useToken } from "wagmi";

import "./styles.scss";

interface BaseAssetTileProps {
  image: string;
  displayName: string;
  action?: ReactNode;
  summary: ReactNode;
  buyNowPrice: string;
  considerationToken?: string;
  isDefault?: boolean;
  navigate?: () => void;
}

export const BaseAssetTile: FC<BaseAssetTileProps> = ({
  image,
  displayName,
  action,
  summary,
  buyNowPrice,
  considerationToken,
  isDefault = false,
  navigate,
}) => {
  const address = useTokenAddress(considerationToken as Address);
  const { data } = useToken({ address, enabled: !!address });
  return (
    <div className="asset-tile" onClick={() => navigate?.()}>
      <>
        <img src={image} />
        <div className="data">
          <div className="display-name">{displayName}</div>
          <TokenPrice
            price={buyNowPrice}
            token={(data?.symbol as Token) ?? Token.ETH}
          />
          <div className="secondary-text">{summary}</div>
          {!isDefault && action}
        </div>
        {isDefault && (
          <div className="defaulted">
            <img src={defaulted} />
          </div>
        )}
      </>
    </div>
  );
};
